// @ts-nocheck
import React, { useEffect, useRef, useState, useCallback } from 'react'

import LayoutDefault from './Layout'
import InputDefault from './Input'
import PreviewDefault from './Preview'
import SubmitButtonDefault from './SubmitButton'
import {
  formatBytes,
  formatDuration,
  accepts,
  resolveValue,
  mergeStyles,
  defaultClassNames,
  getFilesFromEvent as defaultGetFilesFromEvent,
} from './utils'
import { storage } from '../../firebaseApp'
import firebase from 'firebase/compat/app'
// import Typography from '../Typography/Typography'
import { useAppSelector } from '../../store/store'
import { IPlanData } from '../../store/features/plansSlice'
import { useTranslation } from 'react-i18next'
import { customEvent } from '../../utils/customHooks'
import CustomCheckbox from '../Checkbox/Checkbox'

export type StatusValue =
  | 'rejected_file_type'
  | 'rejected_max_files'
  | 'preparing'
  | 'error_file_size'
  | 'error_validation'
  | 'ready'
  | 'started'
  | 'getting_upload_params'
  | 'error_upload_params'
  | 'uploading'
  | 'exception_upload'
  | 'aborted'
  | 'restarted'
  | 'removed'
  | 'error_upload'
  | 'headers_received'
  | 'done'

export type MethodValue =
  | 'delete'
  | 'get'
  | 'head'
  | 'options'
  | 'patch'
  | 'post'
  | 'put'
  | 'DELETE'
  | 'GET'
  | 'HEAD'
  | 'OPTIONS'
  | 'PATCH'
  | 'POST'
  | 'PUT'

export interface IMeta {
  id: string
  status: StatusValue
  type: string // MIME type, example: `image/*`
  name: string
  uploadedDate: string // ISO string
  percent: number
  size: number // bytes
  lastModifiedDate: string // ISO string
  previewUrl?: string // from URL.createObjectURL
  duration?: number // seconds
  width?: number
  height?: number
  videoWidth?: number
  videoHeight?: number
  validationError?: any
}

export interface IFileWithMeta {
  file: File
  meta: IMeta
  cancel: () => void
  restart: () => void
  remove: () => void
  xhr?: XMLHttpRequest
  additionalInfo: {
    key: string
  }
}

export interface IExtra {
  active: boolean
  reject: boolean
  dragged: DataTransferItem[]
  accept: string
  multiple: boolean
  minSizeBytes: number
  maxSizeBytes: number
  maxFiles: number
}

export interface IUploadParams {
  url: string

  body?: string | FormData | ArrayBuffer | Blob | File | URLSearchParams

  meta?: {
    name: string
  }
}

export type CustomizationFunction<T> = (
  allFiles: IFileWithMeta[],
  extra: IExtra,
) => T

export interface IStyleCustomization<T> {
  dropzone?: T | CustomizationFunction<T>
  dropzoneActive?: T | CustomizationFunction<T>
  dropzoneReject?: T | CustomizationFunction<T>
  dropzoneDisabled?: T | CustomizationFunction<T>
  input?: T | CustomizationFunction<T>
  inputLabel?: T | CustomizationFunction<T>
  inputLabelWithFiles?: T | CustomizationFunction<T>
  preview?: T | CustomizationFunction<T>
  previewImage?: T | CustomizationFunction<T>
  submitButtonContainer?: T | CustomizationFunction<T>
  submitButton?: T | CustomizationFunction<T>
}

export interface IExtraLayout extends IExtra {
  onFiles(files: File[]): void
  onCancelFile(file: IFileWithMeta): void
  onRemoveFile(file: IFileWithMeta): void
  onRestartFile(file: IFileWithMeta): void
}

export interface ILayoutProps {
  files: IFileWithMeta[]
  extra: IExtraLayout
  input: React.ReactNode
  previews: React.ReactNode[] | null
  submitButton: React.ReactNode
  uploadAutomatically: React.ReactNode
  dropzoneProps: {
    ref: React.RefObject<HTMLDivElement>
    className: string
    style?: React.CSSProperties
    onDragEnter(event: React.DragEvent<HTMLElement>): void
    onDragOver(event: React.DragEvent<HTMLElement>): void
    onDragLeave(event: React.DragEvent<HTMLElement>): void
    onDrop(event: React.DragEvent<HTMLElement>): void
  }
}

interface ICommonProps {
  files: IFileWithMeta[]
  extra: IExtra
}

export interface IPreviewProps extends ICommonProps {
  meta: IMeta
  className?: string
  imageClassName?: string
  style?: React.CSSProperties
  imageStyle?: React.CSSProperties
  fileWithMeta: IFileWithMeta
  isUpload: boolean
  canCancel: boolean
  canRemove: boolean
  canRestart: boolean
}

export interface IInputProps extends ICommonProps {
  className?: string
  labelClassName?: string
  labelWithFilesClassName?: string
  style?: React.CSSProperties
  labelStyle?: React.CSSProperties
  labelWithFilesStyle?: React.CSSProperties
  getFilesFromEvent: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<File[]>
  accept: string
  multiple: boolean
  disabled: boolean
  content?: React.ReactNode
  withFilesContent?: React.ReactNode
  onFiles: (files: File[]) => void
}

export interface ISubmitButtonProps extends ICommonProps {
  className?: string
  buttonClassName?: string
  style?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  disabled: boolean
  content?: React.ReactNode
  onSubmit: (files: IFileWithMeta[]) => void
  currentPlan: IPlanData
}

type ReactComponent<Props> = (
  props: Props,
) => React.ReactNode | React.Component<Props>

export interface IDropzoneProps {
  onChangeStatus?(
    file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[],
  ): {
    meta: {
      name: string
    }
  } | void
  getUploadParams?(file: IFileWithMeta): IUploadParams
  onSubmit?(successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]): void

  getFilesFromEvent?: (
    event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
  ) => Promise<File[]> | File[]
  getDataTransferItemsFromEvent?: (
    event: React.DragEvent<HTMLElement>,
  ) => Promise<DataTransferItem[]> | DataTransferItem[]

  accept: string
  multiple: boolean
  minSizeBytes: number
  maxSizeBytes: number
  maxFiles: number

  validate?(file: IFileWithMeta): any // usually a string, but can be anything

  autoUpload: boolean
  timeout?: number

  initialFiles?: File[]

  /* component customization */
  disabled: boolean | CustomizationFunction<boolean>

  canCancel: boolean | CustomizationFunction<boolean>
  canRemove: boolean | CustomizationFunction<boolean>
  canRestart: boolean | CustomizationFunction<boolean>

  inputContent: React.ReactNode | CustomizationFunction<React.ReactNode>
  inputWithFilesContent:
    | React.ReactNode
    | CustomizationFunction<React.ReactNode>

  submitButtonDisabled: boolean | CustomizationFunction<boolean>
  submitButtonContent: React.ReactNode | CustomizationFunction<React.ReactNode>

  classNames: IStyleCustomization<string>
  styles: IStyleCustomization<React.CSSProperties>
  addClassNames: IStyleCustomization<string>

  LayoutComponent?: ReactComponent<ILayoutProps>
  PreviewComponent?: ReactComponent<IPreviewProps>
  InputComponent?: ReactComponent<IInputProps>
  SubmitButtonComponent?: ReactComponent<ISubmitButtonProps>
  renderDrop: (file: IFileWithMeta) => JSX.Element
}

const Dropzone: React.FC<IDropzoneProps> = (props) => {
  const [active, setActive] = useState(false)
  const [dragged, setDragged] = useState<DataTransferItem[]>([])
  const [dragTimeoutId, setDragTimeoutId] = useState()
  // const [files, setFiles] = useState<IFileWithMeta[]>([])
  const filesRef = useRef<IFileWithMeta[]>([])
  const dropzoneRef = useRef<HTMLDivElement>(null)
  const mountedRef = useRef(true)
  const prevPropsRef = useRef(props)

  const { userStatus, user }: { userStatus?: IUserStatus } = useAppSelector(
    (store) => store.user,
  )
  const { plans } = useAppSelector((store) => store.plan)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const { transcriptRows } = useAppSelector((store) => store.transcriptSlice)
  const [currentPlan, setCurrentPlan] = useState<IPlanData>()
  const [uploadAutomaticallyChecked, setUploadAutomaticallyChecked] =
    useState(false)
  const { t } = useTranslation()

  const fileStatus = filesRef.current.every(
    (f) =>
      f.meta.status.includes('headers_received') ||
      f.meta.status.includes('done'),
  )
  useEffect(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      if (userPlan) {
        setCurrentPlan(userPlan)
      }
    }
  }, [plans, userStatus])

  useEffect(() => {
    if (uploadAutomaticallyChecked && fileStatus) {
      const files = filesRef.current
      handleSubmit(files)
      setUploadAutomaticallyChecked(false)
    }
  }, [uploadAutomaticallyChecked, filesRef.current, fileStatus])

  const {
    accept,
    multiple,
    maxFiles,
    minSizeBytes,
    maxSizeBytes,
    onSubmit,
    getUploadParams,
    disabled,
    canCancel,
    canRemove,
    canRestart,
    inputContent,
    inputWithFilesContent,
    submitButtonDisabled,
    submitButtonContent,
    classNames,
    styles,
    addClassNames,
    InputComponent,
    PreviewComponent,
    SubmitButtonComponent,
    LayoutComponent,
  } = props

  const forceUpdate = () => {
    if (mountedRef.current) {
      setDragged([...dragged])
    }
  }

  useEffect(() => {
    if (props.initialFiles) {
      handleFiles(props.initialFiles)
    }
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (
      prevPropsRef.current.initialFiles !== props.initialFiles &&
      props.initialFiles
    ) {
      handleFiles(props.initialFiles)
      prevPropsRef.current = props.initialFiles
    }
  }, [props.initialFiles])

  useEffect(() => {
    if (filesRef.current.length > 0 && dropzoneRef.current) {
      setTimeout(() => {
        dropzoneRef.current?.scroll({
          top: dropzoneRef.current.scrollHeight,
          behavior: 'smooth',
        })
      }, 150)
    }
  }, [filesRef.current])

  useEffect(() => {
    return () => {
      mountedRef.current = false
      filesRef.current.forEach((fileWithMeta) => handleCancel(fileWithMeta))
    }
  }, [])

  const getFilesFromEvent = () => {
    return props.getFilesFromEvent || defaultGetFilesFromEvent
  }

  const getDataTransferItemsFromEvent = () => {
    return props.getDataTransferItemsFromEvent || defaultGetFilesFromEvent
  }

  const handleDragEnter = useCallback(
    async (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      const draggedItems = await getDataTransferItemsFromEvent()(e)
      setDragged(draggedItems as DataTransferItem[])
      setActive(true)
    },
    [],
  )

  const handleDragOver = useCallback(
    async (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      clearTimeout(dragTimeoutId)
      const draggedItems = await getDataTransferItemsFromEvent()(e)
      setDragged(draggedItems as DataTransferItem[])
      setActive(true)
    },
    [],
  )

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const timeoutId = window.setTimeout(() => {
      setActive(false)
      setDragged([])
    }, 150)
    setDragTimeoutId(timeoutId)
  }, [])

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setActive(false)
      setDragged([])
      const files = (await getFilesFromEvent()(e)) as File[]
      handleFiles(files)
    },
    [getFilesFromEvent],
  )

  const handleDropDisabled = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setActive(false)
    setDragged([])
  }, [])

  const handleChangeStatus = useCallback(
    (fileWithMeta: IFileWithMeta) => {
      if (!props.onChangeStatus) return
      const { meta = {} } =
        props.onChangeStatus(
          fileWithMeta,
          fileWithMeta.meta.status,
          filesRef.current,
        ) || {}

      if (meta) {
        const { ...restMeta } = meta
        fileWithMeta.meta = {
          ...fileWithMeta.meta,
          ...restMeta,
        }
        forceUpdate()
      }
    },
    [props.onChangeStatus, filesRef.current],
  )

  const handleSubmit = useCallback(
    (files: IFileWithMeta[]) => {
      if (props.onSubmit) props.onSubmit(files, [...files])
    },
    [onSubmit],
  )

  const handleCancel = useCallback(
    (fileWithMeta: IFileWithMeta) => {
      if (fileWithMeta.meta.status !== 'uploading') return
      fileWithMeta.meta.status = 'aborted'
      if (fileWithMeta.xhr) fileWithMeta.xhr.abort()
      handleChangeStatus(fileWithMeta)
      forceUpdate()
    },
    [handleChangeStatus],
  )

  const handleRemove = useCallback(
    (fileWithMeta: IFileWithMeta) => {
      const index = filesRef.current.findIndex((f) => f === fileWithMeta)
      if (index !== -1) {
        URL.revokeObjectURL(fileWithMeta.meta.previewUrl || '')
        fileWithMeta.meta.status = 'removed'
        handleChangeStatus(fileWithMeta)
        const updatedFiles = filesRef.current.slice()
        updatedFiles.splice(index, 1)
        // setFiles(updatedFiles)
        filesRef.current = updatedFiles
        forceUpdate()
      }
    },
    [filesRef.current],
  )

  const handleRestart = useCallback(
    async (fileWithMeta: IFileWithMeta) => {
      if (!props.getUploadParams) return

      if (fileWithMeta.meta.status === 'ready') {
        fileWithMeta.meta.status = 'started'
      } else {
        fileWithMeta.meta.status = 'restarted'
      }
      handleChangeStatus(fileWithMeta)

      fileWithMeta.meta.status = 'getting_upload_params'
      fileWithMeta.meta.percent = 0
      handleChangeStatus(fileWithMeta)
      forceUpdate()
      uploadFile(fileWithMeta)
    },
    [props.getUploadParams],
  )

  const handleFiles = useCallback((files: File[]) => {
    files.forEach((file, index) =>
      handleFile(file, `${new Date().getTime()}-${index}`),
    )
    if (dropzoneRef.current) {
      setTimeout(() => {
        dropzoneRef.current!.scroll({
          top: dropzoneRef.current!.scrollHeight,
          behavior: 'smooth',
        })
      }, 150)
    }
    if (files.length > 0) {
      customEvent('file_uploaded', {
        category: 'transcript',
        user_userId: user.uid,
        accountId: currentAccount.id,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })
    }
  }, [])

  const handleFile = useCallback(
    async (file: File, id: string) => {
      const { name, size, type, lastModified } = file
      const {
        minSizeBytes,
        maxSizeBytes,
        maxFiles,
        accept,
        getUploadParams,
        autoUpload,
        validate,
      } = props

      const uploadedDate = new Date().toISOString()
      const lastModifiedDate =
        lastModified && new Date(lastModified).toISOString()
      const fileWithMeta = {
        file,
        meta: {
          name,
          size,
          type,
          lastModifiedDate,
          uploadedDate,
          percent: 0,
          id,
        },
      } as IFileWithMeta
      const fileName = file.name.toLowerCase()
      const fileExtension = fileName.split('.').pop()
      if (file.type !== 'application/x-moz-file' && !accepts(file, accept)) {
        fileWithMeta.meta.status = 'rejected_file_type'
        handleChangeStatus(fileWithMeta)
        return
      }

      if (filesRef.current.length >= maxFiles) {
        fileWithMeta.meta.status = 'rejected_max_files'
        handleChangeStatus(fileWithMeta)
        return
      }
      fileWithMeta.cancel = () => handleCancel(fileWithMeta)
      fileWithMeta.remove = () => handleRemove(fileWithMeta)
      fileWithMeta.restart = () => handleRestart(fileWithMeta)

      fileWithMeta.meta.status = 'preparing'
      const updatedFiles = [...filesRef.current, fileWithMeta]
      // setFiles(updatedFiles)
      filesRef.current = updatedFiles
      handleChangeStatus(fileWithMeta)
      forceUpdate()

      if (size < minSizeBytes || size > maxSizeBytes) {
        fileWithMeta.meta.status = 'error_file_size'
        handleChangeStatus(fileWithMeta)
        forceUpdate()
        return
      }
      if (
        file.type.startsWith('image/') ||
        file.type === 'application/zip' ||
        file.type === 'application/x-zip-compressed' ||
        file.type === 'application/x-rar-compressed' ||
        file.type === 'application/pdf'
      ) {
        fileWithMeta.meta.status = 'rejected_file_type'
        handleChangeStatus(fileWithMeta)
        forceUpdate()
        return
      }

      await generatePreview(fileWithMeta)

      if (validate) {
        const error = validate(fileWithMeta)
        if (error) {
          fileWithMeta.meta.status = 'error_validation'
          fileWithMeta.meta.validationError = error
          handleChangeStatus(fileWithMeta)
          forceUpdate()
          return
        }
      }
      if (fileExtension === 'aup3') {
        fileWithMeta.meta.status = 'error_upload'
        handleChangeStatus(fileWithMeta)
        forceUpdate()
        return
      }

      if (getUploadParams) {
        if (autoUpload) {
          uploadFile(fileWithMeta)
          fileWithMeta.meta.status = 'getting_upload_params'
        } else {
          fileWithMeta.meta.status = 'ready'
        }
      } else {
        fileWithMeta.meta.status = 'done'
      }
      handleChangeStatus(fileWithMeta)
      forceUpdate()
    },
    [props],
  )

  const generatePreview = useCallback(async (fileWithMeta: IFileWithMeta) => {
    const {
      meta: { type },
      file,
    } = fileWithMeta
    const isImage = type.startsWith('image/')
    const isAudio = type.startsWith('audio/')
    const isVideo = type.startsWith('video/')
    if (!isImage && !isAudio && !isVideo) return

    const objectUrl = URL.createObjectURL(file)

    const fileCallbackToPromise = (
      fileObj: HTMLImageElement | HTMLAudioElement,
    ) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve
          else fileObj.onloadedmetadata = resolve
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000)
        }),
      ])
    }

    try {
      if (isImage) {
        const img = new Image()
        img.src = objectUrl
        fileWithMeta.meta.previewUrl = objectUrl
        await fileCallbackToPromise(img)
        fileWithMeta.meta.width = img.width
        fileWithMeta.meta.height = img.height
      }

      if (isAudio) {
        const audio = new Audio()
        audio.src = objectUrl
        await fileCallbackToPromise(audio)
        fileWithMeta.meta.duration = audio.duration
      }

      if (isVideo) {
        const video = document.createElement('video')
        video.src = objectUrl
        await fileCallbackToPromise(video)
        fileWithMeta.meta.duration = video.duration
        fileWithMeta.meta.videoWidth = video.videoWidth
        fileWithMeta.meta.videoHeight = video.videoHeight
      }
      if (!isImage) URL.revokeObjectURL(objectUrl)
    } catch (e) {
      URL.revokeObjectURL(objectUrl)
    }
    forceUpdate()
  }, [])

  const uploadFile = useCallback(
    async (fileWithMeta: IFileWithMeta) => {
      const { getUploadParams } = props
      if (!getUploadParams) return

      try {
        console.log(fileWithMeta)
        const params: IUploadParams | null = await getUploadParams(fileWithMeta)

        if (params === null) return

        const { url, body, meta: extraMeta = {} } = params
        delete extraMeta.status

        if (!url) {
          fileWithMeta.meta.status = 'error_upload_params'
          fileWithMeta.additionalInfo = {
            key: extraMeta.name,
          }
          handleChangeStatus(fileWithMeta)
          forceUpdate()
          return
        }

        const uploadTask = storage
          .ref(`${url}`)
          .child(`${extraMeta.name}`)
          .put(body)

        fileWithMeta.meta.status = 'uploading'
        fileWithMeta.additionalInfo = {
          key: extraMeta.name,
        }
        handleChangeStatus(fileWithMeta)
        forceUpdate()

        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot: firebase.storage.UploadTaskSnapshot) => {
            fileWithMeta.meta.percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            )
            forceUpdate()
          },
          (error) => {
            console.error('Error uploading', error)
            fileWithMeta.meta.status = 'error_upload'
            handleChangeStatus(fileWithMeta)
            forceUpdate()
          },
          () => {
            fileWithMeta.meta.percent = 100
            fileWithMeta.meta.status = 'done'
            handleChangeStatus(fileWithMeta)
            forceUpdate()
          },
        )
      } catch (err) {
        console.error('Error uploading file', err)
      }
    },
    [props],
  )

  const reject = dragged.some(
    (file) =>
      file.type !== 'application/x-moz-file' && !accepts(file as File, accept),
  )
  const extra = {
    active,
    reject,
    dragged,
    accept,
    multiple,
    minSizeBytes,
    maxSizeBytes,
    maxFiles,
  } as IExtra

  const dropzoneDisabled = resolveValue(disabled, filesRef.current, extra)

  const {
    classNames: {
      dropzone: dropzoneClassName,
      dropzoneActive: dropzoneActiveClassName,
      dropzoneReject: dropzoneRejectClassName,
      dropzoneDisabled: dropzoneDisabledClassName,
      input: inputClassName,
      inputLabel: inputLabelClassName,
      inputLabelWithFiles: inputLabelWithFilesClassName,
      preview: previewClassName,
      previewImage: previewImageClassName,
      submitButtonContainer: submitButtonContainerClassName,
      submitButton: submitButtonClassName,
    },
    styles: {
      dropzone: dropzoneStyle,
      dropzoneActive: dropzoneActiveStyle,
      dropzoneReject: dropzoneRejectStyle,
      dropzoneDisabled: dropzoneDisabledStyle,
      input: inputStyle,
      inputLabel: inputLabelStyle,
      inputLabelWithFiles: inputLabelWithFilesStyle,
      preview: previewStyle,
      previewImage: previewImageStyle,
      submitButtonContainer: submitButtonContainerStyle,
      submitButton: submitButtonStyle,
    },
  } = mergeStyles(classNames, styles, addClassNames, filesRef.current, extra)

  const Input = InputComponent || InputDefault
  const Preview = PreviewComponent || PreviewDefault
  const SubmitButton = SubmitButtonComponent || SubmitButtonDefault
  const Layout = LayoutComponent || LayoutDefault

  let previews = null
  if (PreviewComponent !== null) {
    previews = filesRef.current.map((f) => {
      return (
        <>
          {/* {currentPlan?.stripe_metadata_isFreePlan === 'true' && i === 0 ? (
            <div className="bg-blue-gray-50 flex justify-between items-center mx-7 my-5 px-6 p-3.5 rounded-sm max-w-full transition-all duration-100">
              <Typography className="text-blue-gray-700 text-sm font-normal">
                {t('layout.remainingMinutesNotification', {
                  minutes: Math.floor(
                    Number(currentAccount?.remainingSeconds) / 60,
                  ),
                })}
              </Typography>
            </div>
          ) : (
            ''
          )} */}
          <Preview
            className={previewClassName}
            imageClassName={previewImageClassName}
            style={previewStyle as React.CSSProperties}
            imageStyle={previewImageStyle as React.CSSProperties}
            key={f.meta.id}
            fileWithMeta={f}
            meta={{ ...f.meta }}
            isUpload={Boolean(getUploadParams)}
            canCancel={resolveValue(canCancel, filesRef.current, extra)}
            canRemove={resolveValue(canRemove, filesRef.current, extra)}
            canRestart={resolveValue(canRestart, filesRef.current, extra)}
            files={filesRef.current}
            extra={extra}
            renderDrop={props.renderDrop}
          />
        </>
      )
    })
  }

  const input =
    InputComponent !== null ? (
      <Input
        className={inputClassName}
        labelClassName={inputLabelClassName}
        labelWithFilesClassName={inputLabelWithFilesClassName}
        style={inputStyle as React.CSSProperties}
        labelStyle={inputLabelStyle as React.CSSProperties}
        labelWithFilesStyle={inputLabelWithFilesStyle as React.CSSProperties}
        getFilesFromEvent={
          getFilesFromEvent() as IInputProps['getFilesFromEvent']
        }
        accept={accept}
        multiple={multiple}
        disabled={dropzoneDisabled}
        content={resolveValue(inputContent, filesRef.current, extra)}
        withFilesContent={resolveValue(
          inputWithFilesContent,
          filesRef.current,
          extra,
        )}
        onFiles={handleFiles}
        files={filesRef.current}
        extra={extra}
      />
    ) : null

  const metaSizedisabled =
    filesRef.current && filesRef.current[0] && filesRef.current[0].meta.size

  const submitButton =
    onSubmit && SubmitButtonComponent !== null ? (
      <SubmitButton
        className={`${submitButtonContainerClassName}`}
        buttonClassName={submitButtonClassName}
        style={submitButtonContainerStyle as React.CSSProperties}
        buttonStyle={submitButtonStyle as React.CSSProperties}
        disabled={
          resolveValue(submitButtonDisabled, filesRef.current, extra) ||
          metaSizedisabled === 0
        }
        content={resolveValue(submitButtonContent, filesRef.current, extra)}
        onSubmit={handleSubmit}
        files={filesRef.current}
        extra={extra}
        currentPlan={currentPlan}
      />
    ) : null

  let className = dropzoneClassName
  let style = dropzoneStyle

  if (dropzoneDisabled) {
    className = `${className} ${dropzoneDisabledClassName}`
    style = {
      ...(style || {}),
      ...(dropzoneDisabledStyle || {}),
    }
  } else if (reject) {
    className = `${className} ${dropzoneRejectClassName}`
    style = {
      ...(style || {}),
      ...(dropzoneRejectStyle || {}),
    }
  } else if (active) {
    className = `${className} ${dropzoneActiveClassName}`
    style = {
      ...(style || {}),
      ...(dropzoneActiveStyle || {}),
    }
  }
  const uploadAutomaticallyCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setUploadAutomaticallyChecked(event.target.checked)
    customEvent('file_uploader_autoupload_checked', {
      accountId: currentAccount.id,
      user_userId: user.uid,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
      actionSource: event.target.checked
        ? 'enable-autoupload'
        : 'disable-autoupload',
    })
  }

  const uploadAutomatically = (
    <div className="px-4">
      <CustomCheckbox
        name="Automatically upload files"
        label={t('layout.automaticallyUpload')}
        checked={uploadAutomaticallyChecked}
        onChange={uploadAutomaticallyCheckBox}
        labelProps={{
          className: 'font-normal text-sm text-blue-gray-500',
        }}
      />
    </div>
  )

  return (
    <Layout
      input={input}
      previews={previews}
      submitButton={submitButton}
      uploadAutomatically={uploadAutomatically}
      dropzoneProps={{
        ref: dropzoneRef,
        className: `${className} ${
          className == 'dzu-dropzone drop-container-no-border'
            ? 'min-h-0 flex flex-row mt-7 px-7'
            : ''
        } ${
          transcriptRows.length === 0 &&
          className !== 'dzu-dropzone drop-container-no-border'
            ? 'min-h-[445px]'
            : 'z-0'
        }`,
        style: style as React.CSSProperties,
        onDragEnter: handleDragEnter,
        onDragOver: handleDragOver,
        onDragLeave: handleDragLeave,
        onDrop: dropzoneDisabled ? handleDropDisabled : handleDrop,
      }}
      files={filesRef.current}
      extra={
        {
          ...extra,
          onFiles: handleFiles,
          onCancelFile: handleCancel,
          onRemoveFile: handleRemove,
          onRestartFile: handleRestart,
        } as IExtraLayout
      }
    />
  )
}

Dropzone.defaultProps = {
  accept: '*',
  multiple: true,
  minSizeBytes: 0,
  maxSizeBytes: Number.MAX_SAFE_INTEGER,
  maxFiles: Number.MAX_SAFE_INTEGER,
  autoUpload: true,
  disabled: false,
  canCancel: true,
  canRemove: true,
  canRestart: true,
  inputContent: 'Drag Files or Click to Browse',
  inputWithFilesContent: 'Add Files',
  submitButtonDisabled: false,
  submitButtonContent: 'Submit',
  classNames: {},
  styles: {},
  addClassNames: {},
  renderDrop: () => <> </>,
}

export default Dropzone
export {
  LayoutDefault as Layout,
  InputDefault as Input,
  PreviewDefault as Preview,
  SubmitButtonDefault as SubmitButton,
  formatBytes,
  formatDuration,
  accepts,
  defaultClassNames,
  defaultGetFilesFromEvent as getFilesFromEvent,
}
