import React, { useCallback, useMemo, useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IStripePrice } from '../../interfaces'
import { IPlanData } from '../../store/features/planSlice'
import { convertToDate, customEvent } from '../../utils/customHooks'
import { useAppSelector } from '../../store/store'
import { Chip } from '@material-tailwind/react'
import { addMilliseconds, differenceInMilliseconds } from 'date-fns'

interface selectedPlan {
  id?: string
  product: string
}
interface Imetadata {
  allowedSeconds: string
  bookACall: string
  feature1: string
  feature2: string
  feature3: string
  feature4: string
  feature5: string
  isFreePlan: string
  isVisible: string
  portalAccess: string
}

interface IProps {
  active: boolean
  description: string | null
  metadata: Imetadata
  name: string
  role?: string
  stripe_metadata_allowedSeconds: string
  tax_code: null | string
  prices: IStripePrice[]
  handleclick: (id: string) => void
  handleOpen: () => void
  selectedPlan?: selectedPlan
  currentPlan?: IPlanData
  planID?: string
  index: number
  isFilteredPlan: boolean
}
function CheckIcon() {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3656 0.734473C11.5156 0.884496 11.5998 1.08794 11.5998 1.30007C11.5998 1.5122 11.5156 1.71565 11.3656 1.86567L4.96562 8.26567C4.8156 8.41565 4.61215 8.4999 4.40002 8.4999C4.18789 8.4999 3.98444 8.41565 3.83442 8.26567L0.63442 5.06567C0.488694 4.91479 0.408059 4.71271 0.409881 4.50295C0.411704 4.2932 0.495839 4.09255 0.644166 3.94422C0.792492 3.79589 0.993142 3.71176 1.2029 3.70993C1.41266 3.70811 1.61474 3.78875 1.76562 3.93447L4.40002 6.56887L10.2344 0.734473C10.3844 0.584497 10.5879 0.500244 10.8 0.500244C11.0122 0.500244 11.2156 0.584497 11.3656 0.734473Z"
        fill="#7B809A"
      />
    </svg>
  )
}

const PricingCard: React.FC<IProps> = ({
  name,
  prices,
  currentPlan,
  selectedPlan,
  planID,
  metadata,
  handleclick,
  handleOpen,
  index,
  isFilteredPlan,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { currentProject } = useAppSelector((store) => store.project)
  const { user, userData } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { discountOffer } = useAppSelector((store) => store.plan)

  const handleFunction = (): void => {
    setLoading(true)
    handleclick(prices[0].id)
    customEvent('billing_upgrade_plan_opened', {
      projectId: currentProject?.id || '',
      accountId: currentAccount?.id,
      user_userId: user.uid,
      actionMetadata: `plan-${planID}`,
    })
  }

  const isSecondCard = index === 1

  const _checkPlan = useCallback(() => {
    if (
      currentPlan?.prices &&
      prices?.length > 0 &&
      prices[0].unit_amount &&
      currentPlan.prices[0].unit_amount &&
      currentPlan.prices[0].unit_amount < prices[0].unit_amount
    ) {
      return <span>{t('upgradeModal.upgrade')}</span>
    } else if (
      currentPlan?.prices &&
      prices?.length > 0 &&
      currentPlan?.metadata?.isFreePlan === 'true'
    ) {
      return <span>{t('upgradeModal.upgrade')}</span>
    } else {
      return <span>{t('upgradeModal.downgrade')}</span>
    }
  }, [currentPlan, prices])

  const couponExpirationTime = useMemo(() => {
    if (currentAccount && currentAccount.createdAt) {
      const accountCreationTime = convertToDate(currentAccount.createdAt)
      const targetTime = addMilliseconds(
        accountCreationTime,
        24 * 60 * 60 * 1000,
      )
      const remainingTime = differenceInMilliseconds(targetTime, new Date())
      return remainingTime
    } else {
      return -1
    }
  }, [currentAccount])

  return (
    <>
      {metadata.isVisible ? (
        // <Card
        //   className={`w-[24rem] min-h-[34rem] grid bg-transparent border ${
        //     isSecondCard
        //       ? 'border-lstnGreen-500 h-[38rem] py-14'
        //       : 'border-gray-100 py-8'
        //   } px-8 shadow-none`}
        //   placeholder={undefined}
        // >
        <Card
          className={` w-[20rem] sm:w-[22rem] min-h-[30rem] sm:min-h-[34rem] grid overflow-auto ${
            isSecondCard ? 'bg-white z-10' : 'bg-transparent'
          } border ${
            (!isFilteredPlan && isSecondCard) ||
            (selectedPlan && selectedPlan.product == planID)
              ? 'border-lstnGreen-500 sm:h-[38rem] py-8 sm:py-8'
              : 'border-gray-100 py-8'
          } px-8 shadow-md hover:shadow group hover:border-lstnGreen-500`}
          placeholder={undefined}
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 rounded-none pb-4 sm:pb-6 text-center"
            placeholder={undefined}
          >
            <Typography className="font-bold text-base text-blue-gray-900">
              {name}
            </Typography>
            {userData &&
              discountOffer &&
              couponExpirationTime > 0 &&
              userData?.userType !== 'Business' &&
              metadata.bookACall !== 'true' && (
                <Typography className="font-bold text-base text-blue-gray-900 mt-3">
                  <span style={{ textDecoration: 'line-through' }}>
                    €{prices[0].unit_amount && prices[0].unit_amount / 100}
                  </span>{' '}
                  <span className="self-end">
                    /{prices[0]?.interval.slice(0, 2)}
                  </span>
                </Typography>
              )}
            {metadata.bookACall === 'true' ? (
              <Typography className="mt-4 flex justify-center gap-1 text-5xl font-normal text-gray-900 z-10">
                <span className="text-5xl font-bold">Custom</span>
              </Typography>
            ) : discountOffer &&
              discountOffer.length > 0 &&
              couponExpirationTime > 0 ? (
              <Typography className="mt-4 flex justify-center gap-1 text-5xl font-normal text-gray-900 group-hover:text-lstnGreen-500 z-10">
                <span className="text-5xl font-bold">
                  €
                  {prices[0].unit_amount &&
                    (prices[0].unit_amount -
                      (prices[0].unit_amount * discountOffer[0].percent_off) /
                        100) /
                      100}
                </span>{' '}
                <span className="self-end text-2xl">
                  /{prices[0]?.interval.slice(0, 2)}
                </span>
              </Typography>
            ) : (
              <Typography className="mt-4 flex justify-center gap-1 text-5xl font-normal text-gray-900 group-hover:text-lstnGreen-500 z-10">
                <span className="text-5xl font-bold">
                  €{prices[0].unit_amount && prices[0].unit_amount / 100}
                </span>{' '}
                <span className="self-end text-2xl">
                  /{prices[0]?.interval.slice(0, 2)}
                </span>
              </Typography>
            )}
            {userData &&
              discountOffer &&
              couponExpirationTime > 0 &&
              userData?.userType !== 'Business' &&
              metadata.bookACall !== 'true' && (
                <div className="flex justify-center mt-4">
                  {userData.userType === 'Student' ? (
                    <Chip
                      variant="ghost"
                      value="80% DISCOUNT!"
                      className="w-fit group-hover:bg-lstnGreen-100"
                    />
                  ) : (
                    <Chip
                      variant="ghost"
                      value="50% DISCOUNT!"
                      className="w-fit group-hover:bg-lstnGreen-100"
                    />
                  )}
                </div>
              )}
          </CardHeader>
          <CardBody className="p-0" placeholder={undefined}>
            <ul className="flex flex-col gap-2 sm:gap-4">
              {Object.keys(metadata)
                .filter((key) => /^feature\d+$/.test(key))
                .sort((a, b) => {
                  const numA = parseInt(a.replace('feature', ''), 10)
                  const numB = parseInt(b.replace('feature', ''), 10)
                  return numA - numB
                })
                .map((key, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <span className="p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="text-sm font-normal text-blue-gray-500">
                      {t(`upgradeModal.${metadata[key]}`)}
                    </Typography>
                  </li>
                ))}
            </ul>
          </CardBody>
          <CardFooter
            className="mt-6 p-0 flex items-end"
            placeholder={undefined}
          >
            {selectedPlan?.product == planID ? (
              <Typography className=" uppercase text-xs p-4 font-bold text-blue-gray-900 text-center w-full">
                {t('upgradeModal.yourPlan')}
              </Typography>
            ) : metadata.bookACall === 'true' ? (
              <Button
                size="lg"
                className="hover:bg-lstnGreen-500"
                ripple={false}
                fullWidth={true}
                onClick={handleOpen}
                placeholder="CONTACT US"
              >
                {t('upgradeModal.contactUs')}
              </Button>
            ) : (
              <Button
                size="lg"
                className={`${
                  isSecondCard ? 'bg-lstnGreen-500 ' : 'text-white'
                } hover:bg-lstnGreen-500 flex items-center justify-center`}
                ripple={false}
                fullWidth={true}
                onClick={handleFunction}
                placeholder="upgrade"
              >
                {loading ? (
                  <CircularProgress
                    size={18}
                    color="inherit"
                    className="mr-2"
                  />
                ) : (
                  ''
                )}
                {_checkPlan()}
              </Button>
            )}
          </CardFooter>
        </Card>
      ) : null}
    </>
  )
}
export default PricingCard
