import React from 'react'
import Typography from '../components/Typography/Typography'
import { Button } from '@material-tailwind/react'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import DesktopMacIcon from '@material-ui/icons/DesktopMac'
import BusinessIcon from '../icons/Business.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { database } from '../firebaseApp'
import firebase from 'firebase/compat/app'
import { IAPi } from '../interfaces'
import { customEvent } from '../utils/customHooks'
import { useAppSelector } from '../store/store'
interface IupdateUserData {
  profilePicture?: string | undefined
  transcriptionLanguage?: string
  preferredLanguage?: string
  name?: string | undefined
  receiveNotification?: boolean
  showPunctuation?: boolean
  filterProfanity?: boolean
  apiKeys?: Array<IAPi>
  userType?: string
}
interface IProps {
  user: firebase.User
}
const ProfileRole: React.FC<IProps> = ({ user }) => {
  const [ishover, setIshover] = React.useState(false)
  const { currentAccount } = useAppSelector((store) => store.account)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const accountId = currentAccount?.id
  //   const location = useLocation()
  //   console.log(location)
  async function updateUserData(
    id: string,
    data: IupdateUserData,
  ): Promise<void> {
    try {
      const userRef = database.collection(`users`).doc(id)
      const accRef = database.collection(`accounts`).doc(accountId)
      const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
      await userRef.update({ ...data, updatedAt: updatedAt })
      await accRef.update(data)
      customEvent('account_identified_itself_as', {
        accountId: currentAccount.id,
        user_userId: user.uid,
        actionMetadata: data.userType,
      })
    } catch (error) {
      console.error('error', error)
    }
  }
  const handleNext = async (role: string) => {
    if (role === 'Business') {
      await updateUserData(user.uid, { userType: role })
      navigate('/create-account')
    } else {
      await updateUserData(user.uid, { userType: role })
      navigate('/transcripts')
    }
  }

  return (
    <div className="flex flex-col items-center py-16">
      <div>
        <Typography
          variant="h4"
          color="blue-gray"
          className="text-2xl font-semibold text-center text-blue-gray-900"
        >
          {t('profileRole.heading')}
        </Typography>
        <Typography
          variant="h4"
          color="blue-gray"
          className="text-sm text-center text-blue-gray-300"
        >
          {t('profileRole.subHeading')}
        </Typography>
      </div>
      <div className="flex flex-row gap-4 mt-8">
        <Button
          variant="outlined"
          placeholder={undefined}
          className="w-32 h-28 rounded-lg bg-transparent border-blue-gray-200  group hover:border-lstnGreen-500 shadow-md hover:shadow-lg hover:bottom-2"
          onClick={() => handleNext('Student')}
        >
          <ImportContactsIcon
            className="text-blue-gray-500 group-hover:text-lstnGreen-500"
            style={{ fontSize: 40 }}
          />
          <Typography
            variant="h4"
            color="blue-gray"
            className="text-sm font-normal text-center text-blue-gray-300 mt-2 group-hover:text-lstnGreen-500"
          >
            {t('profileRole.student')}
          </Typography>
        </Button>
        <Button
          variant="outlined"
          placeholder={undefined}
          className="w-32 h-28 rounded-lg bg-transparent border-blue-gray-200 group hover:border-lstnGreen-500 shadow-md hover:shadow-lg hover:bottom-2"
          onClick={() => handleNext('Freelancer')}
        >
          <DesktopMacIcon
            className="text-blue-gray-500 group-hover:text-lstnGreen-500"
            style={{ fontSize: 40 }}
          />
          <Typography
            variant="h4"
            color="blue-gray"
            className="text-sm font-normal text-center text-blue-gray-300 mt-2 group-hover:text-lstnGreen-500"
          >
            {t('profileRole.freelancer')}
          </Typography>
        </Button>
        <Button
          variant="outlined"
          placeholder={undefined}
          className="w-32 h-28 rounded-lg bg-transparent border-blue-gray-200 group hover:border-lstnGreen-500 shadow-md hover:shadow-lg hover:bottom-2"
          onClick={() => handleNext('Business')}
          onMouseOver={() => setIshover(true)}
          onMouseOut={() => setIshover(false)}
        >
          <div className="flex justify-center">
            <img
              src={BusinessIcon}
              className={`w-9 h-9 ${ishover ? 'greenfill' : ''}`}
              alt=""
            />
          </div>
          <Typography
            variant="h4"
            color="blue-gray"
            className="text-sm font-normal text-center text-blue-gray-300 mt-2 group-hover:text-lstnGreen-500"
          >
            {t('profileRole.business')}
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default ProfileRole
