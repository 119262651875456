import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  setError,
  setNestedAllFolders,
  setNestedAllSharedFolders,
  setNestedAllSharedTranscripts,
  setNestedAllTranscripts,
  setProjectTranscripts,
  setTranscript,
  setProjectFolders,
  setTranslationLanguages,
} from './transcriptsSlice'
import { database } from '../../../firebaseApp'
import firebase from 'firebase/compat/app'
import { AppDispatch } from '../../store'
import { IFolders, ITranscript } from '../../../interfaces'
import { translationTargetLanguagesDeepL } from '../../../utils/translationLanguages'

export interface IFetchParams {
  id: string
  folderData: IFolders | null
  role: {
    [key: string]: {
      [key: string]: Array<string>
    }
  }
  folders: IFolders[]
  folderShared: IFolders[]
  transcript: ITranscript[]
  transcriptShared: ITranscript[]
  currentAccountID: string
  currentProjectID: string
  currentAccountRole: {
    status: string
    role: string
  }
}

export const fetchallFolders = createAsyncThunk(
  'fetchallFolders',
  async (data: IFolders[], { dispatch }) => {
    try {
      dispatch(setNestedAllFolders(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchallSharedFolders = createAsyncThunk(
  'fetchallSharedFolders',
  async (data: IFolders[], { dispatch }) => {
    try {
      dispatch(setNestedAllSharedFolders(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchAllTranscript = createAsyncThunk(
  'fetchAllTranscripts',
  async (data: ITranscript[], { dispatch }) => {
    try {
      dispatch(setNestedAllTranscripts(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchallSharedTranscripts = createAsyncThunk(
  'fetchallSharedTranscripts',
  async (data: ITranscript[], { dispatch }) => {
    try {
      dispatch(setNestedAllSharedTranscripts(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchallProjectFolders = createAsyncThunk(
  'fetchallProjectFolders',
  async (data: IFolders[], { dispatch }) => {
    try {
      dispatch(setProjectFolders(data))
    } catch (error) {
      console.log(error)
    }
  },
)
export const fetchallProjectTranscripts = createAsyncThunk(
  'fetchallProjectTranscripts',
  async (data: ITranscript[], { dispatch }) => {
    try {
      dispatch(setProjectTranscripts(data))
    } catch (error) {
      console.log(error)
    }
  },
)

// @ts-ignore
export const fetchTranscriptSubs = async ({ dispatch }) => {
  try {
    const allFoldersQuery = database
      .collection('folders')
      .where('userId', '==', firebase.auth().currentUser?.uid)
    const allSharedFoldersQuery = database
      .collection('folders')
      .where(
        'sharedUserIds',
        'array-contains',
        firebase.auth().currentUser?.uid,
      )
    const allTranscriptsQuery = database
      .collection('transcripts')
      .where('userId', '==', firebase.auth().currentUser?.uid)
      .orderBy('createdAt', 'desc')
    const allSharedTranscriptsQuery = database
      .collection('transcripts')
      .where(
        'sharedUserIds',
        'array-contains',
        firebase.auth().currentUser?.uid,
      )

    const transcriptsUnsubscribe = allTranscriptsQuery.onSnapshot(
      (transcriptsSnapshot) => {
        const allTranscripts = transcriptsSnapshot.docs.map((doc) => doc.data())
        dispatch(fetchAllTranscript(allTranscripts))
      },
    )

    const sharedTranscriptsUnsubscribe = allSharedTranscriptsQuery.onSnapshot(
      (sharedTranscriptsSnapshot) => {
        const allSharedTranscripts = sharedTranscriptsSnapshot.docs.map((doc) =>
          doc.data(),
        )
        dispatch(fetchallSharedTranscripts(allSharedTranscripts))
      },
    )
    const foldersUnsubscribe = allFoldersQuery.onSnapshot((foldersSnapshot) => {
      const allFolders = foldersSnapshot.docs.map((doc) => doc.data())
      dispatch(fetchallFolders(allFolders as IFolders[]))
    })

    const sharedFoldersUnsubscribe = allSharedFoldersQuery.onSnapshot(
      (sharedFoldersSnapshot) => {
        const allSharedFolders = sharedFoldersSnapshot.docs.map((doc) =>
          doc.data(),
        )
        dispatch(fetchallSharedFolders(allSharedFolders as IFolders[]))
      },
    )

    return () => {
      foldersUnsubscribe()
      sharedFoldersUnsubscribe()
      transcriptsUnsubscribe()
      sharedTranscriptsUnsubscribe()
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const projectTranscript = async (
  projectId: string,
  dispatch: AppDispatch,
) => {
  try {
    const folderRef = database
      .collection('folders')
      .where('projectId', '==', projectId)

    const transcriptsRef = database
      .collection('transcripts')
      .where('projectId', '==', projectId)

    folderRef.onSnapshot((foldersSnapshot) => {
      const allprojectfolders = foldersSnapshot.docs.map((doc) => doc.data())
      dispatch(fetchallProjectFolders(allprojectfolders as IFolders[]))
    })

    transcriptsRef.onSnapshot((transcriptsSnapshot) => {
      const allprojectTranscript = transcriptsSnapshot.docs.map((doc) =>
        doc.data(),
      )
      dispatch(fetchallProjectTranscripts(allprojectTranscript))
    })
  } catch (error) {
    console.error(error)
  }
}

export const fetchTranscriptFolder = createAsyncThunk(
  'fetchTranscriptFolder',
  async ({
    id,
    folderData,
    role,
    folders,
    folderShared,
    transcript,
    transcriptShared,
    currentAccountID,
    currentProjectID,
    currentAccountRole,
  }: IFetchParams): Promise<any> => {
    try {
      const filterFolders = await folders.filter(
        (folder: IFolders) =>
          folder.parentFolderId == (folderData ? folderData.id : '') &&
          (folder.projectId == (currentProjectID ? currentProjectID : '') ||
            (!('projectId' in folder) && !currentProjectID)) &&
          folder.accountId == currentAccountID,
      )
      const filterfolderShared = await folderShared.filter(
        (folder: IFolders) =>
          folder.parentFolderId == (folderData ? folderData.id : '') &&
          folder.projectId == (currentProjectID ? currentProjectID : ''),
        // &&
        // folder.accountId == currentAccountID,
      )
      const filtertranscript = await transcript.filter(
        (transcript: ITranscript) =>
          transcript.folderId == (folderData ? folderData.id : '') &&
          (transcript.projectId == (currentProjectID ? currentProjectID : '') ||
            (!('projectId' in transcript) && !currentProjectID)) &&
          transcript.accountId == currentAccountID,
      )
      const filterTranscriptShared = await transcriptShared.filter(
        (transcript: ITranscript) =>
          transcript.folderId == (folderData ? folderData.id : '') &&
          transcript.projectId == (currentProjectID ? currentProjectID : ''),
        // &&
        // transcript.accountId == currentAccountID,
      )

      return {
        id: id,
        folderData: folderData ? folderData : undefined,
        roles: role,
        currentAccountRole: currentAccountRole,
        folders: JSON.stringify(filterFolders),
        foldersShareUserId: JSON.stringify(filterfolderShared),
        transcriptList: JSON.stringify(filtertranscript),
        transcriptListShareUserId: JSON.stringify(filterTranscriptShared),
      }
    } catch (error) {
      console.error(error)
    }
  },
)

export const fetchTransctipt =
  (id: string) => async (dispatch: AppDispatch) => {
    // let translationLanguages: any = []
    try {
      const TransctiptQuery = database.collection('transcripts').doc(id)
      TransctiptQuery.onSnapshot((snapshot) => {
        const transcript = snapshot.data()
        dispatch(setTranscript(transcript))
        dispatch(fetchTranslationList(id))
        console.log(snapshot.ref, 'fjebf')
      })
    } catch (error) {
      console.log(error)
      dispatch(setError(error))
    }
  }

export const fetchTranslationList =
  (id: string) => async (dispatch: AppDispatch) => {
    // let translationLanguages: any = []
    // try {
    //   const promises = translationTargetLanguagesDeepL.map((lan) => {
    //     const name = `paragraphs-${lan?.value}`
    //     const subCollectionRef = database.collection(
    //       `transcripts/${id}/${name}`,
    //     )
    //     return new Promise((resolve) => {
    //       subCollectionRef.onSnapshot((snapshot) => {
    //         if (!snapshot.empty) {
    //           resolve(lan)
    //         } else {
    //           resolve(null)
    //         }
    //       })
    //     })
    //   })

    //   return Promise.all(promises)
    //     .then((results) => {
    //       const filteredLanguages = results.filter((lan) => lan !== null)
    //       dispatch(setTranslationLanguages(filteredLanguages))
    //     })
    //     .catch((error) => {
    //       console.error('Error checking sub-collections:', error)
    //     })
    // } catch (error) {
    //   console.log(error)
    //   dispatch(setError(error))
    // }
    try {
      const token = await firebase?.auth()?.currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/transcription/getUserTranslateTranscript?transcriptId=${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const data = await response.json()
      if (data.collectionList) {
        const checkList = data.collectionList.map(
          (lan: any) => lan.split('-')[1],
        )

        const filteredLanguages = translationTargetLanguagesDeepL.filter(
          (lan) => checkList.length > 0 && checkList.includes(lan.value),
        )
        // console.log(filteredLanguages, 'filteredLanguages')
        dispatch(setTranslationLanguages(filteredLanguages))
      }
    } catch (error) {
      console.log(error)
    }
  }
