import React, { useEffect, useState, useCallback } from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'
import './css/lstn-core.css'
import './css/lstn-base.css'
import './css/App.css'
import './css/ModalWindow.css'
import 'semantic-ui-css/semantic.min.css'
import './css/styles.css'
import Index from './components/Index'
import PaymentScreen from './components/PaymentScreen'
import PortalScreen from './components/PortalScreen'
import Transcripts from './pages/Transcripts'
import TranscriptDetails from './pages/TranscriptDetails'
import { PrivateRoute } from './Routing/Routes'
import './utils/script'
import { toast } from 'react-toastify'
import firebase from 'firebase/compat/app'
import Settings from './pages/Settings'
import { IUser } from './interfaces'
import RolesProvider from './context/ContextProvider'
import ReactGA from 'react-ga4'
import SignUp from './components/Signup'
import ResetComponent from './components/Signup/ResetPassword'
import { useAppSelector, useAppDispatch } from './store/store'
import { fetchUserById, helperUserData } from './store/features/userSlice'
import { database } from './firebaseApp'
import CreateAccount from './pages/CreateAccount'
import Projects from './pages/Projects'
import InvitationProceed from './pages/InvitationProceed'
import Layout from './components/Layout'
import AccountSettings from './pages/AccountSettings'
import { fetchAccounts } from './store/features/accountSlice'
import ProfileRole from './pages/ProfileRole'

const trackingCode: string | undefined = process.env.REACT_APP_GA4_PROPERTY_ID

ReactGA.initialize([
  {
    trackingId: trackingCode ? trackingCode : '',
  },
])
interface clientIdData {
  gaClientId: string
  // Add other properties as needed
}
export interface Tracker {
  get(key: string): Promise<string>
  // Add other methods if needed
}
interface IStateProps {
  firebase?: typeof firebase
  user?: firebase.User
  userData?: IUser[]
  isEnterprise?: boolean
}

const App: React.FC<IStateProps & RouteProps> = () => {
  const dispatch = useAppDispatch()
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [gaClientId, setGaClientId] = useState('')
  const { user, userData, Enterprise } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const accountObject = localStorage.getItem('selectedAccount')

  const isInvitedAccount =
    userData.accounts &&
    Object.keys(userData.accounts).filter(
      (key) => userData.accounts[key].role !== 'owner' && key !== 'updatedAt',
    )

  const accountId =
    userData && userData.accounts
      ? accountObject
        ? JSON.parse(accountObject).accountId
        : isInvitedAccount.length > 0
          ? isInvitedAccount[0]
          : userData.accounts && Object.keys(userData.accounts)[0]
      : ''

  const auth = firebase.auth()
  const userUid = auth.currentUser?.uid

  useEffect(() => {
    if (Object.keys(currentAccount).length === 0 && userUid) {
      dispatch(fetchUserById(userUid))
      helperUserData({ dispatch })
    }
  }, [userUid])

  useEffect(() => {
    if (Object.keys(currentAccount).length === 0 && accountId) {
      fetchAccounts({ dispatch, id: accountId })
      // dispatch(fetchAllAccounts({ dispatch }))
      // fetchAllProjects(accountId, dispatch)
      // fetchRoles(dispatch)
    }
  }, [userData])

  useEffect(() => {
    toast.configure({ position: 'top-right', hideProgressBar: true })
    if (firebase) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          setUserLoggedIn(true)
        }
      })
    }
    if (user && user.uid && (window as any).gist) {
      handleMessenger()
    }
  }, [user])
  useEffect(() => {
    ReactGA.ga(async (tracker: Tracker) => {
      const ClientId = await tracker.get('clientId')
      setGaClientId(ClientId)
    })
  }, [])
  async function updateClientId(id: string, data: clientIdData) {
    try {
      const userRef = database.collection(`users`).doc(id)
      await userRef.update({ ...data })
    } catch (error) {
      console.error('error', error)
    }
  }
  useEffect(() => {
    if (userLoggedIn && user && user.uid && userData) {
      initializeAnalytics(user)
    }
  }, [userData])

  const initializeAnalytics = useCallback(
    async (user: firebase.User) => {
      // let provider
      // let gaClientId

      if (trackingCode) {
        // ReactGA.initialize(trackingCode, {
        //   // debug: false,
        //   // titleCase: false,
        //   gaOptions: {
        //     userId: user.uid,
        //     clientId: userData.gaClientId,
        //   },
        // })
        ReactGA.gtag('set', 'user_properties', {
          user_userId: user.uid,
        })
        ReactGA.gtag('set', 'user_properties', {
          user_id: user.uid,
        })
        if (userData && userData.gaClientId !== gaClientId) {
          try {
            await updateClientId(user.uid, { gaClientId })
          } catch (err) {
            console.log('User update failed')
          }
        }
      }

      ReactGA.gtag('set', 'user_properties', {
        user_userId: user.uid,
      })
      // ReactGA.set({ dimension9: gaClientId })
      // ReactGA.set({ dimension10: user.uid })

      // if (user.providerData && user.providerData[0]) {
      //   provider = getProvider(user.providerData[0].providerId)
      // }

      // ReactGA.event({
      //   action: "user log in",
      //   category: "authentication",
      //   label: provider,
      // });

      setUserLoggedIn(false)
    },
    [userData],
  )

  const handleMessenger = useCallback(() => {
    if (user) {
      ;(window as any).gist.identify(user.uid, {
        email: user.email,
        user_id: user.uid,
      })
    }
  }, [user])

  let isEnterprise = Enterprise ? Enterprise : false

  if (userData && userData.isEnterprise) {
    isEnterprise = userData.isEnterprise as boolean
  }

  let currentUser

  if (firebase) {
    currentUser = firebase.auth().currentUser
  }

  return (
    <>
      {/* @ts-ignore */}
      <BrowserRouter>
        <RolesProvider>
          {/* <GAListener> */}
          <Layout user={user} isEnterprise={isEnterprise}>
            <Routes>
              <Route
                path="/"
                element={
                  user && user.uid && Object.keys(currentAccount).length > 0 ? (
                    <Navigate to="/transcripts" />
                  ) : (
                    <SignUp />
                  )
                }
              />
              <Route
                path="/login"
                element={
                  user && user.uid && Object.keys(currentAccount).length > 0 ? (
                    <Navigate to="/transcripts" />
                  ) : (
                    <Index />
                  )
                }
              />
              <Route path="/reset-password" element={<ResetComponent />} />
              <Route path="/sign-up" element={<Navigate to="/" />} />
              <Route path="/invite/:id" element={<InvitationProceed />} />
              <Route
                path="/profile-role"
                element={
                  <PrivateRoute currentUser={currentUser}>
                    <ProfileRole user={user} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/create-account/:inviteId?"
                element={
                  <PrivateRoute currentUser={currentUser}>
                    <CreateAccount user={user} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/transcripts"
                element={
                  <PrivateRoute currentUser={currentUser}>
                    <Transcripts user={user} currentUser={currentUser} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/transcript/folder/:id?"
                element={
                  <PrivateRoute currentUser={currentUser}>
                    <Transcripts user={user} currentUser={currentUser} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/transcript/:id?"
                element={
                  <PrivateRoute currentUser={currentUser}>
                    <TranscriptDetails user={user} currentUser={currentUser} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute currentUser={currentUser}>
                    <Settings user={user} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/projects"
                element={
                  <PrivateRoute currentUser={currentUser}>
                    <Projects user={user} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/project/:id?"
                element={
                  <PrivateRoute user={user} currentUser={currentUser}>
                    <Transcripts user={user} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/project/folder/:id?"
                element={
                  <PrivateRoute user={user} currentUser={currentUser}>
                    <Transcripts user={user} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/account-settings"
                element={
                  <PrivateRoute currentUser={currentUser}>
                    <AccountSettings user={user} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/payment-plan"
                element={
                  // (routeProps) => (
                  <PaymentScreen
                    // {...routeProps}
                    user={user}
                    isEnterprise={isEnterprise}
                  />
                  // )
                }
              />
              <Route
                path="/user-portal"
                element={
                  // (routeProps) => (
                  <PortalScreen
                    // {...routeProps}
                    user={user}
                  />
                  // )
                }
              />
            </Routes>
          </Layout>
          {/* </GAListener> */}
        </RolesProvider>
      </BrowserRouter>
    </>
  )
}

export default App
