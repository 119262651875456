import React, { useEffect, useMemo, useState } from 'react'
import firebase from 'firebase/compat/app'
import Header from './Header'
import Sidebar from './Sidebar'
import { useLocation } from 'react-router-dom'
import { Button } from '@material-tailwind/react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { convertToDate, customEvent } from '../utils/customHooks'
import { useAppSelector } from '../store/store'
import Typography from './Typography/Typography'
import { useTranslation } from 'react-i18next'
import { addMilliseconds, differenceInMilliseconds } from 'date-fns'
import UpgradeModal from './UpgradeModal'

type LayoutProps = {
  children: React.ReactNode
  user: firebase.User
  isEnterprise?: boolean
}

interface CountdownTimerProps {
  createdAt: { seconds: number; nanoseconds: number }
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ createdAt }) => {
  const accountCreationTime = convertToDate(createdAt)

  const targetTime = addMilliseconds(accountCreationTime, 24 * 60 * 60 * 1000)

  const [timeLeft, setTimeLeft] = useState<number>(
    differenceInMilliseconds(targetTime, new Date()),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date()
      const remainingTime = differenceInMilliseconds(targetTime, currentTime)

      if (remainingTime <= 0) {
        clearInterval(interval)
        setTimeLeft(0) // Countdown end
      } else {
        setTimeLeft(remainingTime)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [targetTime])

  const hours = Math.floor(timeLeft / (1000 * 60 * 60))
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)

  return (
    <span>
      {hours} hours {minutes} minutes {seconds} seconds
    </span>
  )
}

const Layout: React.FC<LayoutProps> = ({ children, user, isEnterprise }) => {
  // const transcript = useAppSelector((store) => store.paragraphsSlice)
  const { currentAccount } = useAppSelector((store) => store.account)
  const location = useLocation()
  const storedSidebarCollapse = localStorage.getItem('sidebarCollapse')
  const [sidebarCollapse, setSidebarCollapse] = React.useState(
    storedSidebarCollapse ? JSON.parse(storedSidebarCollapse) : false,
  )
  const [UpgradeOpen, setUpgradeOpen] = useState(false)
  const { userData } = useAppSelector((store) => store.user)
  const { discountOffer } = useAppSelector((store) => store.plan)
  const { t } = useTranslation()
  const checkPath = useMemo(() => {
    const currentPath = location && location.pathname
    if (
      currentPath === '/transcripts' ||
      currentPath.includes('/transcript/folder/') ||
      currentPath === '/projects' ||
      currentPath.includes('/project')
    ) {
      return location.pathname
    } else {
      return
    }
  }, [location])
  useEffect(() => {
    localStorage.setItem('sidebarCollapse', JSON.stringify(sidebarCollapse))
  }, [sidebarCollapse])
  // console.log(location.pathname, 'log')
  const handleSidebarCollapse = () => {
    setSidebarCollapse(!sidebarCollapse)
    customEvent('nav_sidebar_collaps_status_change', {
      accountId: currentAccount.id,
      user_userId: user && user.uid,
      actionSource: 'from-chevron-icon',
      actionMetadata: sidebarCollapse
        ? 'nav-sidebar-collapsed'
        : 'nav-sidebar-opened',
    })
  }

  const couponExpirationTime = useMemo(() => {
    if (currentAccount && currentAccount.createdAt) {
      const accountCreationTime = convertToDate(currentAccount.createdAt)
      const targetTime = addMilliseconds(
        accountCreationTime,
        24 * 60 * 60 * 1000,
      )
      const remainingTime = differenceInMilliseconds(targetTime, new Date())
      return remainingTime
    } else {
      return -1
    }
  }, [currentAccount])

  const handleUpgrade = (): void => {
    if (!UpgradeOpen) {
      customEvent('billing_upgrade_modal_opened', {
        category: 'accounts',
        accountId: currentAccount.id,
        user_userId: userData.id,
        actionSource: 'navbar-cta',
      })
    }
    setUpgradeOpen(!UpgradeOpen)
  }

  return (
    <>
      {discountOffer &&
        discountOffer?.length > 0 &&
        userData &&
        couponExpirationTime > 0 &&
        Object.keys(userData).length > 0 &&
        userData?.userType !== 'Business' && (
          <div className="w-full h-9 bg-lstnGreen-500 flex justify-center text-center items-center">
            <Typography className="text-base font-medium text-white flex items-center gap-1">
              {t('roleBasedDiscount.student')}
              <span
                className="underline cursor-pointer"
                onClick={handleUpgrade}
              >
                {' '}
                {t('roleBasedDiscount.discount', {
                  discount:
                    (discountOffer && discountOffer[0]?.percent_off) || 0,
                })}
              </span>
              {t('roleBasedDiscount.forNext')}
              {currentAccount && currentAccount.createdAt ? (
                <CountdownTimer createdAt={currentAccount.createdAt} />
              ) : (
                '00:00:00'
              )}
            </Typography>
          </div>
        )}
      <div className="flex">
        {user && user.uid && checkPath && (
          <div className="h-screen hidden lg:flex top-0 sticky">
            <div
              className={`h-screen hidden lg:flex  ${
                sidebarCollapse ? 'w-[18rem]' : 'w-[5rem]'
              } sticky top-0 hover:w-[18rem] group transition-all duration-300`}
            >
              <Sidebar sidebarCollapse={sidebarCollapse} />
            </div>
            <div className="absolute bottom-10 right-[-20px] ">
              <Button
                size="sm"
                placeholder={undefined}
                className=" rounded-full p-2 bg-white border border-blue-gray-50 text-black flex justify-center items-center"
                onClick={handleSidebarCollapse}
              >
                <ArrowForwardIosIcon
                  fontSize="small"
                  className={`${
                    sidebarCollapse ? 'rotate-180' : ''
                  } group-hover:transition-transform`}
                />
              </Button>
            </div>
          </div>
        )}
        <div className="w-full">
          {user && user.uid && location.pathname !== '/create-account' ? (
            <Header
              isEnterprise={isEnterprise}
              userId={user.uid}
              children={undefined}
            />
          ) : (
            ''
          )}
          {children}
        </div>
      </div>
      <UpgradeModal UpgradeOpen={UpgradeOpen} handleUpgrade={handleUpgrade} />
    </>
  )
}

export default Layout
