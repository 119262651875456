import React, { useState, useCallback, useMemo, useEffect } from 'react'
// import { DropFilesEventHandler } from "react-dropzone"
import firebase from 'firebase/compat/app'
import Dropzone, {
  IExtra,
  IFileWithMeta,
  IUploadParams,
  defaultClassNames,
  StatusValue,
  ILayoutProps,
} from './UploaderPreview/Dropzone'
// import ReactGA from "react-ga"
import ReactGA from 'react-ga4'
import { compose } from 'redux'
import { withTranslation, TransProps } from 'react-i18next'
// import WarningDialog from './VideoInfoDialog'
import { database } from '../firebaseApp'
import { IUserStatus } from '../interfaces/IUser'
// import ModalWindow from './ModalWindow'
import {
  IFolders,
  IMetadata,
  ITranscript,
  IUploadData,
  IUser,
} from '../interfaces'
import {
  InteractionType,
  MicrophoneDistance,
  OriginalMediaType,
  ProgressType,
  RecordingDeviceType,
} from '../enums'
import '../css/uploader.css'
import { fetchUserById } from '../store/features/userSlice'
import { useAppDispatch, useAppSelector } from '../store/store'
import { customEvent } from '../utils/customHooks'
import { Tooltip } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
import Selector from './SelectInput/Select'
import Typography from './Typography/Typography'
// const VALID_MIME_TYPES_RE = /audio\/|video\/|application\/mxf/

interface UpdateUserData {
  showWarning: boolean
  // Add other properties as needed
}
interface IProps {
  fileSelected: (file: File) => void
  userStatus: IUserStatus
  userId: string
  // getUserStatus: (userId: string) => void
  userData: IUser
  createTranscript: boolean
  updateUser: (id: string, data: UpdateUserData) => Promise<void>
  folderData?: IFolders
  checkTranscriptDuration: () => void
}

interface IFileData {
  file: File
  languageCodes: string[]
  duration: number
}

interface ITranscribeFiles {
  [key: string]: IFileData
}
//@ts-ignore
const UploadButton: React.FC<IProps & TransProps> = (props) => {
  // const [errorMessage, setErrorMessage] = useState<{
  //   heading?: string
  //   description?: string
  // }>({})
  // const [showExpiredError, setShowExpiredError] = useState(false)
  const [dataPopulated, setDataPopulated] = useState(false)
  const [transcribeFiles, setTranscribeFiles] = useState<ITranscribeFiles>({})
  // const [openWarning, setOpenWarning] = useState(false)
  const [temporaryFiles, setTemporaryFiles] = useState<IFileWithMeta[]>([])
  const [isdisabled, setIsdisabled] = useState(false)
  const { userStatus }: { userStatus?: IUserStatus } = useAppSelector(
    (store) => store.user,
  )
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const { transcriptRows } = useAppSelector((store) => store.transcriptSlice)
  const dispatch = useAppDispatch()
  const accountId = currentAccount?.id
  const { t } = useTranslation()
  // const navigate = useNavigate()
  // const toggleExpiredError = useCallback(
  //   (status: boolean, daysError?: boolean) => {
  //     const { t } = props
  //     setShowExpiredError(status)
  //     if (status) {
  //       if (daysError) {
  //         setErrorMessage({
  //           heading: t && t('uploadButton.expireHeading'),
  //           description: t && t('uploadButton.expireDescription'),
  //         })
  //       } else {
  //         setErrorMessage({
  //           heading: t && t('uploadButton.trialHeading'),
  //           description: t && t('uploadButton.trialDescription'),
  //         })
  //       }
  //     }
  //   },
  //   [props],
  // )

  // const handleCloseWarningDialog = useCallback(
  //   (avoidReceive: boolean) => {
  //     const updatedTemporaryFiles = [...temporaryFiles]
  //     // setOpenWarning(false)
  //     props.updateUser(props.userId, {
  //       showWarning: !avoidReceive,
  //     })
  //     updatedTemporaryFiles.forEach((file) => {
  //       file.restart()
  //     })

  //     setTemporaryFiles([])
  //   },
  //   [temporaryFiles, props],
  // )

  useCallback(() => {
    // Fetch user status here if needed
    // props.getUserStatus(props.userId)
    dispatch(fetchUserById(props.userId))
  }, [props])

  // const redirectToUpdatePlan = () => navigate('/payment-plan')
  // const handleSecondaryAction = () => toggleExpiredError(false)
  const Layout = ({
    input,
    previews,
    submitButton,
    uploadAutomatically,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      <div>
        {previews}
        <div {...dropzoneProps}>
          {files.length > 0 && submitButton}
          {files.length < maxFiles && input}
        </div>
        {files.length > 0 && uploadAutomatically}
      </div>
    )
  }

  const availableLanguages = useMemo(() => {
    const languages = new Map([
      ['en-GB', 'English (UK)'],
      ['en-US', 'English (USA)'],
      ['en-AU', 'English (Australia)'],
      ['en-IN', 'English (India)'],
      // ["en-IE", "English (Ireland)"],
      // ["en-GB", "English (UK)"],
      // ["en-US", "English (USA)"],
      ['it-IT', 'Italian'],
      ['pl-PL', 'Polish'],
      ['pt-PT', 'Portuguese (Portugal)'],
      ['pt-BR', 'Portuguese (Brazil)'],
      ['de-DE', 'German'],
      ['fr-FR', 'French (France)'],
      // ["fr-FR", "French (France)"],
      ['es-ES', 'Spanish (Spain)'],
      // ["es-AR", "Spanish (Latin America)"],
      // ["es-ES", "Spanish (Spain)"],
      ['es-AR', 'Spanish (Latin America)'],
      // ["es-AR", "Spanish (Argentina)"],
      // ["es-BO", "Spanish (Bolivia)"],
      // ["es-CL", "Spanish (Chile)"],
      // ["es-CO", "Spanish (Colombia)"],
      // ["es-CR", "Spanish (Costa Rica)"],
      // ["es-DO", "Spanish (Dominican Republic)"],
      // ["es-EC", "Spanish (Ecuador)"],
      // ["es-SV", "Spanish (El Salvador)"],
      // ["es-GT", "Spanish (Guatemala)"],
      // ["es-HN", "Spanish (Honduras)"],
      // ["es-MX", "Spanish (Mexico)"],
      // ["es-NI", "Spanish (Nicaragua)"],
      // ["es-PA", "Spanish (Panama)"],
      // ["es-PY", "Spanish (Paraguay)"],
      // ["es-PE", "Spanish (Peru)"],
      // ["es-PR", "Spanish (Puerto Rico)"],
      // ["es-ES", "Spanish (Spain)"],
      // ["es-UY", "Spanish (Uruguay)"],
      ['es-US', 'Spanish (USA)'],
      // ["es-VE", "Spanish (Venezuela)"],
      ['da-DK', 'Danish'],
      ['ko-KR', 'Korean'],
      ['sv-SE', 'Swedish'],
      ['no-NO', 'Norwegian'],
      ['fi-FI', 'Finnish'],
      ['nl-NL', 'Dutch'],
      ['ro-RO', 'Romanian'],
      // ["hu-HU", "Hungarian"],
      ['uk-UA', 'Ukrainian'],
      ['ru-RU', 'Russian'],
      ['bg-BG', 'Bulgarian'],
      ['cs-CZ', 'Czech'],
      // ["hr-HR", "Croatian"],
      // ["el-GR", "Greek"],
      // ["sk-SK", "Slovak"],
      // ["sl-SI", "Slovenian"],
      ['km-KH', 'Khmer'],
    ])
    return Array.from(languages).map(([key, value]) => ({
      label: value,
      value: key,
    }))
    // <option key={key} value={key}>
    //   {' '}
    //   {value}{' '}
    // </option>
  }, [])
  useEffect(() => {
    if (
      (userStatus && userStatus && userStatus.status !== 'active') ||
      (userStatus?.remainingSeconds && userStatus.remainingSeconds <= 0) ||
      (userStatus?.additionalSeconds && userStatus.additionalSeconds < -1)
    ) {
      setIsdisabled(true)
    } else {
      setIsdisabled(false)
    }
  }, [userStatus, userStatus?.remainingSeconds])
  const handleLanguageChange = useCallback(
    (index: number, value: string | undefined, fileName: string) => {
      if (!value) {
        return
      }
      const key = fileName.replace('-original', '')
      const updatedTranscribeFiles = { ...transcribeFiles }
      const languageCodes = updatedTranscribeFiles[key].languageCodes // Accessing languageCodes directly from the copied object
      languageCodes[index] = value

      setTranscribeFiles(updatedTranscribeFiles)
      // console.log(transcribeFiles, 'transcribeFiles')
    },
    [transcribeFiles],
  )

  const renderLanguageDrop = useCallback(
    (file: IFileWithMeta) => {
      const { additionalInfo } = file
      if (additionalInfo && additionalInfo.key) {
        const key = additionalInfo.key.replace('-original', '')
        const fileData = transcribeFiles[key]
        return (
          <Selector
            options={availableLanguages}
            onChange={(value) =>
              handleLanguageChange(0, value, additionalInfo.key)
            }
            selectedValue={fileData.languageCodes[0]}
            selectClassName="border-blue-gray-50 border-2"
            className="relative text-xs font-normal w-40 min-w-[160px] languageSelector text-blue-gray-500 bg-white rounded-lg"
            children={undefined}
          />
          // <label
          //   className="org-label uploader-preview-drop"
          //   data-cy="data-project-upload-language"
          // >
          //   {fileData && fileData.languageCodes && (
          //     <select
          //       value={fileData.languageCodes[0]}
          //       onChange={(event) =>
          //         handleLanguageChange(0, event, additionalInfo.key)
          //       }
          //     >
          //       {availableLanguages}
          //     </select>
          //   )}
          // </label>
        )
      } else {
        return <> </>
      }
    },
    [availableLanguages, handleLanguageChange, transcribeFiles],
  )
  const handleButtonClick = (
    e: React.MouseEventHandler<HTMLDivElement> | any,
  ) => {
    const isFromRef = e?.nativeEvent.pointerType === ''
    customEvent('file_uploader_finder_opened', {
      category: 'transcripts',
      user_userId: props.userId,
      accountId: accountId,
      actionSource: isFromRef
        ? 'from-empty-table-placeholder"'
        : 'from-file-uploader',
    })
  }

  const inputContent = useCallback(
    (files: File[], extra: IExtra) => {
      // setDataPopulated(false)
      setDataPopulated((prevDataPopulated) => {
        if (files.length > 0 && !prevDataPopulated) {
          return true
        } else if (files.length === 0 && prevDataPopulated) {
          return false
        }
        return prevDataPopulated
      })

      if (extra.reject) {
        return 'Video files only'
      } else {
        if (isdisabled) {
          return (
            <Tooltip
              className="bg-lstnGreen-100 text-blue-gray-400"
              content={t('uploadIcon.disableTooltip')}
            >
              <div
                className={`org-btn org-btn--round lstn-btn uploader-div-btn shadow-lg rounded-full h-16 flex items-center justify-center ${
                  transcriptRows.length === 0 ? 'px-4' : 'px-3'
                }`}
                ref={props.uploadref}
              >
                <svg width="40" height="40" aria-hidden="true">
                  <use xlinkHref="#icon-pluss" />
                </svg>
                {dataPopulated && <span>Data is populated.</span>}
              </div>
            </Tooltip>
          )
        } else {
          return (
            <div>
              <div
                className={`org-btn org-btn--round w-fit h-16 rounded-full lstn-btn ml-0 uploader-div-btn shadow-lg flex items-center justify-center ${
                  transcriptRows.length === 0 ? 'px-4' : 'px-3'
                }`}
                ref={props.uploadref}
                onClick={handleButtonClick}
              >
                <svg width="40" height="40" aria-hidden="true">
                  <use xlinkHref="#icon-pluss" />
                </svg>
                {transcriptRows.length === 0 ? (
                  <span className="font-medium text-2xl">Upload file</span>
                ) : (
                  ''
                )}
                {/* {dataPopulated && <span>Data is populated.</span>} */}
              </div>
              {transcriptRows.length === 0 ? (
                <div>
                  <Typography className="text-xs font-normal text-blue-gray-300 text-center">
                    or drag and drop a file here
                  </Typography>
                </div>
              ) : (
                ''
              )}
            </div>
          )
        }
      }
    },
    [dataPopulated, isdisabled, transcriptRows],
  )

  const getUploadParams = useCallback(
    ({ file, meta }: IFileWithMeta): IUploadParams => {
      let lng = 'it-IT'
      if (localStorage.getItem('lstn-trans-lng') === 'es') {
        lng = 'es-ES'
      } else if (localStorage.getItem('lstn-trans-lng') === 'en') {
        lng = 'en-US'
      }

      const transcriptId = database.collection('/transcripts').doc().id
      transcribeFiles[transcriptId] = {
        file: file,
        languageCodes: [lng],
        duration: meta.duration ? (meta.duration / 60) * 60 : 0,
      }
      if (meta.duration === 0) {
        return {
          body: undefined,
          url: '',
          meta: {
            name: `${transcriptId}-original`,
          },
        }
      }
      setTranscribeFiles(transcribeFiles)
      let sum = 0
      const values = Object.values(transcribeFiles)
      for (const value of values) {
        sum += value.duration
      }

      // return {
      // 	body: file,
      // 	url: `/media/${this.props.userId}`,
      // 	meta: {
      // 		name: `${transcriptId}-original`
      // 	}
      // }
      if (userStatus?.remainingSeconds && sum > userStatus.remainingSeconds) {
        props.checkTranscriptDuration()
      }
      if (
        (userStatus && userStatus.status === 'active') ||
        (userStatus?.remainingSeconds && sum < userStatus.remainingSeconds)
      ) {
        return {
          body: file,
          url: `/media/${props.userId}`,
          meta: {
            name: `${transcriptId}-original`,
          },
        }
      }
      // toggleExpiredError(true)
      return {
        body: undefined,
        url: '',
        meta: {
          name: `${transcriptId}-original`,
        },
      }
    },
    [
      transcribeFiles,
      userStatus && userStatus.status,
      userStatus && userStatus.remainingSeconds,
      props.userId,
      // toggleExpiredError,
    ],
  )

  const createTranscript = useCallback(
    async (fileData: IFileData, key: string) => {
      try {
        const file = fileData.file

        const fileNameAndExtension = [
          file.name.substr(0, file.name.lastIndexOf('.')),
          file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length),
        ]

        let name = ''
        let fileExtension = ''
        if (fileNameAndExtension.length === 2) {
          name = fileNameAndExtension[0]
          fileExtension = fileNameAndExtension[1]
        }

        // Uploaded Data
        const uploadedData: IUploadData = {
          fileSize: file.size * 1e-6,
          duration: fileData.duration,
        }

        // Metadata
        const metadata: IMetadata = {
          fileExtension,
          interactionType: InteractionType.Unspecified,
          languageCodes: fileData.languageCodes,
          microphoneDistance: MicrophoneDistance.Unspecified,
          originalMediaType: OriginalMediaType.Video,
          originalMimeType: file.type,
          recordingDeviceType: RecordingDeviceType.Unspecified,
          profanityFilter:
            props.userData.filterProfanity !== undefined
              ? props.userData.filterProfanity
              : true,
          enableAutomaticPunctuation:
            props.userData.showPunctuation !== undefined
              ? props.userData.showPunctuation
              : true,
        }

        // Add non empty fields

        const transcriptId = key

        let sharedUserIds: string[] = []
        let sharedUserRoles: {} = {}
        const userId: string = props.userId

        if (props.folderData && props.folderData.id) {
          sharedUserIds = props.folderData.sharedUserIds!
          sharedUserRoles = props.folderData.sharedUserRoles!
          if (
            props.folderData.userId !== props.userId &&
            Object.keys(currentProject).length === 0
          ) {
            sharedUserIds.push(props.folderData.userId)
            sharedUserRoles[props.folderData.userId] = 'sharedEdit'
          }
        }

        const transcript: ITranscript = {
          accountId: accountId,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
          id: transcriptId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          metadata,
          name,
          status: {
            percent: 100,
            progress: ProgressType.Uploading,
          },
          userId,
          uploadedData,
          folderId:
            props.folderData && props.folderData.id ? props.folderData.id : '',
          sharedUserIds: sharedUserIds ? sharedUserIds : [],
          sharedUserRoles: sharedUserRoles != null ? sharedUserRoles : {},
        }
        await database.doc(`transcripts/${transcriptId}`).set(transcript)

        customEvent('transcript_created', {
          category: 'transcripts',
          transcriptId: transcriptId,
          folderId:
            props.folderData && props.folderData.id ? props.folderData.id : '',
          user_userId: props.userId,
          accountId: accountId,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        })
        // ReactGA.event({
        //   action: "transcript_created",
        //   category: "transcripts",
        //   label: props.folderData && props.folderData.id ? props.folderData.id : "",
        // })
      } catch (error) {
        console.error('Error here:::::: ', error)
        // ReactGA.exception({
        //   description: error.message,
        //   fatal: false,
        // })
        ReactGA.gtag('event', 'transcript_created_exception', {
          description: (error as Error).message,
          fatal: false,
        })
      }
    },
    [
      props.userData?.filterProfanity,
      props.userData && props.userData.showPunctuation,
      props.userId,
      props.folderData,
      currentProject.id,
    ],
  )

  const handleSubmit = useCallback(
    async (successFiles: IFileWithMeta[]) => {
      customEvent('transcript_create_requested', {
        category: 'transcripts',
        folderId:
          props.folderData && props.folderData.id ? props.folderData.id : '',
        user_userId: props.userId,
        accountId: accountId,
      })
      const keys = Object.keys(transcribeFiles)
      for (const key of keys) {
        const fileData = transcribeFiles[key]
        await createTranscript(fileData, key)
      }
      successFiles.forEach((f) => f.remove())
      setTranscribeFiles({})
      setTemporaryFiles([])
      // props.getUserStatus(props.userId)
      dispatch(fetchUserById(props.userId))
    },
    [transcribeFiles, props, createTranscript],
  )

  const handleChangeStatus = useCallback(
    (file: IFileWithMeta, status: StatusValue) => {
      if (status === 'removed') {
        if (file.additionalInfo) {
          const key = file.additionalInfo.key.replace('-original', '')
          delete transcribeFiles[key]
          setTranscribeFiles(transcribeFiles)
        } else {
          file.cancel()
        }
      }

      if (status === 'ready') {
        // if (props?.userData?.showWarning !== false) {
        //   temporaryFiles.push(file)

        //   // setOpenWarning(true)
        // } else {
        file.restart()
        // }
      }
    },
    [props?.userData?.showWarning, temporaryFiles, transcribeFiles],
  )

  return (
    <>
      {/* <WarningDialog
        // openDialog={openWarning}
        onClose={handleCloseWarningDialog}
      /> */}
      <div
        data-tut="DropZone"
        className={
          dataPopulated
            ? 'drop-parent-container rounded-lg border-blue-gray-300'
            : ''
        }
        title={
          props.createTranscript
            ? 'Folder is read only. You cannot upload files.'
            : ''
        }
        data-cy="data-project-upload"
      >
        <Dropzone
          disabled={props.createTranscript || isdisabled}
          accept="*"
          autoUpload={false}
          // @ts-ignore
          inputContent={inputContent}
          getUploadParams={getUploadParams}
          LayoutComponent={Layout}
          onSubmit={handleSubmit}
          submitButtonContent={props.t && props.t('uploadButton.transcribe')}
          inputWithFilesContent={props.t && props.t('uploadButton.add')}
          onChangeStatus={handleChangeStatus}
          classNames={{
            dropzone: `${defaultClassNames.dropzone} ${
              !dataPopulated
                ? transcriptRows.length === 0
                  ? 'drop-container pulsate-border overflow-hidden'
                  : 'drop-container'
                : 'drop-container-no-border'
            }`,
            dropzoneReject: `${defaultClassNames.dropzoneReject} drop-reject`,
            submitButtonContainer: `${defaultClassNames.submitButtonContainer} uploader-submit-btn-container`,
            submitButton: `${defaultClassNames.submitButton} uploader-submit-btn`,
            inputLabelWithFiles: `${defaultClassNames.inputLabelWithFiles} uploader-addFiles-btn`,
            preview: `${defaultClassNames.preview} uploader-progress-container`,
          }}
          renderDrop={renderLanguageDrop}
          multiple={true}
          minSizeBytes={0}
          maxSizeBytes={Number.MAX_SAFE_INTEGER}
          maxFiles={Number.MAX_SAFE_INTEGER}
          canCancel={true}
          canRemove={true}
          canRestart={true}
          submitButtonDisabled={false}
          styles={{}}
          addClassNames={{}}
        />
      </div>
      {/* @ts-ignore */}
      {/* {showExpiredError && (
        <ModalWindow
          handlePrimaryAction={redirectToUpdatePlan}
          handleSecondaryAction={handleSecondaryAction}
          heading={errorMessage.heading}
          description={errorMessage.description}
        />
      )} */}
    </>
  )
}

export default compose<React.ElementType>(withTranslation())(UploadButton)
