import React, { useEffect, useState } from 'react'
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from '@material-ui/core'
// import Slide from '@material-ui/core/Slide'
// import { ThemeProvider } from '@material-ui/styles'
// import { TransitionProps } from '@material-ui/core/transitions'
// import { makeStyles } from '@material-ui/core/styles'
// import TreeView from '@material-ui/lab/TreeView'
// import TreeItem from '@material-ui/lab/TreeItem'
// import { lstnBasictheme } from '../../theme/lstn-theme-basic'
import { Folder } from '@material-ui/icons'
import { roleType } from '../../enums'
// import { useTranslation } from 'react-i18next'
import { IFolders, ITranscript, ITranscriptRow } from '../../interfaces'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useAppSelector } from '../../store/store'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { IFetchParams } from '../../store/features/transcript/helper'
import {
  checkDuplicates,
  filterTranscriptData,
  parseToFoldersList,
  parseToTranscriptList,
} from '../../utils/parseData'
import File from '../../icons/tableFile.svg'
import AddFolder from '../../icons/addFolder.svg'
import { database } from '../../firebaseApp'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CustomTooltip from '../Tooltip/Tooltip'
import { fetchProjectData } from '../../store/features/projectSlice'
import CircularProgress from '@material-ui/core/CircularProgress'

interface IProps {
  open: boolean
  moveFolderBoxSelect: (id: string, projectId?: string) => void
  folderHierarchy?: IFolders[]
  checkedRowsData: ITranscriptRow[]
  moveFolderBox: (status: boolean) => void
  folderData?: IFolders
  combinedProps: any
  // handleCreateNewFolderClose: (status: boolean, value?: string) => void
}
interface SpaceItemProps {
  name: string
  id: string
  status?: string
  setSecondDialogOpen?: (status: boolean) => void
}
interface ProjectSpace {
  folders: IFolders[]
  transcripts: ITranscriptRow[]
}
// interface treeItems {
//   id: string
//   name: string
//   children?: treeItems[]
// }

// const Transition = React.forwardRef<unknown, TransitionProps>(
//   function Transition(props, ref) {
//     // @ts-ignore
//     return <Slide direction="up" ref={ref} {...props} />
//   },
// )
const styles = {
  fontSize: 24,
  marginBottom: '1px',
  color: '#455A64',
}

const fetchAllTranscriptFolder = async ({
  id,
  folderData,
  role,
  folders,
  folderShared,
  transcript,
  transcriptShared,
  currentAccountID,
  currentProjectID,
  currentAccountRole,
}: IFetchParams): Promise<any> => {
  try {
    const filterFolders = await folders.filter(
      (folder: IFolders) =>
        folder.parentFolderId == (folderData ? folderData.id : '') &&
        (folder.projectId == (currentProjectID ? currentProjectID : '') ||
          (!('projectId' in folder) && !currentProjectID)) &&
        folder.accountId == currentAccountID,
    )
    const filterfolderShared = await folderShared.filter(
      (folder: IFolders) =>
        folder.parentFolderId == (folderData ? folderData.id : '') &&
        folder.projectId == (currentProjectID ? currentProjectID : ''),
    )
    const filtertranscript = await transcript.filter(
      (transcript: ITranscript) =>
        transcript.folderId == (folderData ? folderData.id : '') &&
        (transcript.projectId == (currentProjectID ? currentProjectID : '') ||
          (!('projectId' in transcript) && !currentProjectID)) &&
        transcript.accountId == currentAccountID,
    )
    const filterTranscriptShared = await transcriptShared.filter(
      (transcript: ITranscript) =>
        transcript.folderId == (folderData ? folderData.id : '') &&
        transcript.projectId == (currentProjectID ? currentProjectID : ''),
    )

    let transcriptList: any = []
    let transcriptListShareUserId: any = []
    let getFolders: ITranscriptRow[]
    let foldersShareUserId: ITranscriptRow[]
    let transcripts: ITranscript[]
    let transcriptRows: any = []
    const roles: any = role
    if (filtertranscript) {
      transcriptList = filterTranscriptData(filtertranscript)
    }

    if (filterTranscriptShared) {
      transcriptListShareUserId = filterTranscriptData(filterTranscriptShared)
    }

    if (filterFolders && filterFolders.length > 0) {
      getFolders = parseToFoldersList(
        filterFolders,
        roles,
        id,
        currentAccountRole,
      )
    } else {
      getFolders = []
    }

    if (filterfolderShared && filterfolderShared.length > 0) {
      foldersShareUserId = parseToFoldersList(
        filterfolderShared,
        roles,
        id,
        currentAccountRole,
      )
    } else {
      foldersShareUserId = []
    }
    transcripts = [...transcriptList, ...transcriptListShareUserId]

    if (transcripts && transcripts.length > 0) {
      transcriptRows = parseToTranscriptList(
        transcripts,
        roles,
        id,
        currentAccountRole,
      )
    }

    transcriptRows = [
      ...checkDuplicates([...getFolders, ...foldersShareUserId]),
      ...transcriptRows,
    ]
    return transcriptRows
  } catch (error) {
    console.error(error)
  }
}

export default function MoveDialog(props: IProps) {
  const [navigationStack, setNavigationStack] = useState<string[]>([])
  const [selectedMoveFolder, setSelectedMoveFolder] = useState('')
  // const [selectedFolder, setSelectedFolder] = useState<string[]>([])
  const [selectedSpaceId, setSelectedSpaceId] = useState('')
  const [secondDialogOpen, setSecondDialogOpen] = useState(false)
  const [accountpersonalSpaceRole, setAccountpersonalSpaceRole] = useState<
    string[]
  >([])
  const { accountRoles, user } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { projects, roles } = useAppSelector((store) => store.project)
  const [spaceData, setSpaceData] = useState<ITranscriptRow[]>([])
  const { nestedBackup } = useAppSelector((store) => store.transcriptSlice)
  // const { t } = useTranslation()

  // Folder state
  const [folderDialog, setFolderDialog] = useState({
    isOpen: false,
    isLoading: false,
  })
  const [initialValueFolder, setInitialValueFolder] = useState('')
  const handleFolderDialogOpen = () =>
    setFolderDialog((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }))

  const [selectedProjectSpaceData, setSelectedProjectSpaceData] =
    useState<ProjectSpace>({ folders: [], transcripts: [] })

  const filteredProjects = projects.filter(
    (project) => project.users[user.uid] && project.status !== 'archived',
  )
  const filteredfolderData: IFolders[] = [
    ...nestedBackup.allFolders,
    ...selectedProjectSpaceData.folders,
  ].filter((folder) => folder.id === selectedMoveFolder)
  useEffect(() => {
    if (accountRoles && Object.keys(currentAccount).length > 0) {
      const currentAccountRole = currentAccount?.users[user.uid!]
      const personalSpaceRole =
        accountRoles[currentAccountRole?.role]?.['personalSpace']
      setAccountpersonalSpaceRole(personalSpaceRole)
    }
  }, [accountRoles, currentAccount])

  //   const useStyles = makeStyles({
  //     root: {
  //       maxWidth: 600,
  //       width: '-webkit-fill-available',
  //     },
  //   })
  // console.log(props.folderHierarchy, 'folderHierarchy')
  useEffect(() => {
    const fetchSelectedSpaceData = async () => {
      if (selectedSpaceId !== 'personalSpace' && selectedSpaceId !== '') {
        const fetchAllProjectData = await fetchProjectData(selectedSpaceId)
        setSelectedProjectSpaceData(fetchAllProjectData as ProjectSpace)
      } else {
        setSelectedProjectSpaceData({ folders: [], transcripts: [] })
      }
    }
    fetchSelectedSpaceData()
  }, [props.combinedProps, selectedSpaceId])

  useEffect(() => {
    const fetchAllData = async () => {
      const fetchData = await fetchAllTranscriptFolder({
        id: props.combinedProps.userId,
        folderData: filteredfolderData[0],
        role: props.combinedProps.role,
        folders: props.combinedProps.allFolders,
        folderShared: [
          ...props.combinedProps.allSharedFolders,
          ...(selectedProjectSpaceData?.folders || []),
        ],
        transcript: props.combinedProps.allTranscripts,
        transcriptShared: [
          ...props.combinedProps.allSharedTranscripts,
          ...(selectedProjectSpaceData?.transcripts || []),
        ],
        currentAccountID: props.combinedProps.currentAccountID,
        currentProjectID:
          selectedSpaceId == 'personalSpace' ? '' : selectedSpaceId,
        currentAccountRole: props.combinedProps.currentAccountRole,
      })
      if (fetchData) {
        setSpaceData(fetchData)
      }
    }
    fetchAllData()
  }, [
    props.combinedProps,
    selectedSpaceId,
    selectedMoveFolder,
    selectedProjectSpaceData,
  ])
  // const folderHierarchy = [
  //   {
  //     id: 'root',
  //     name: !props.folderData ? 'Personal Space' : '',
  //     children: props.folderHierarchy,
  //   },
  // ]

  const handleMove = () => {
    if (selectedSpaceId == 'personalSpace') {
      if (selectedMoveFolder == '') {
        props.moveFolderBoxSelect('root')
      } else {
        props.moveFolderBoxSelect(selectedMoveFolder)
      }
    } else {
      if (selectedMoveFolder == '') {
        props.moveFolderBoxSelect('root', selectedSpaceId)
      } else {
        props.moveFolderBoxSelect(selectedMoveFolder, selectedSpaceId)
      }
    }
  }

  const handleClose = (status: boolean) => {
    props.moveFolderBox(status)
    setSelectedSpaceId('')
  }

  // useEffect(() => {
  //   const ids: string[] = []
  //   props.checkedRowsData.map((row) => {
  //     if (row.status == 'Folder') {
  //       ids.push(row.id)
  //     }
  //   })
  //   setSelectedFolder(ids)
  // }, [props.checkedRowsData])

  //   //console.log(folderHierarchy)
  // const getTreeItemsFromData = (treeItems: treeItems[]) => {
  //   return treeItems.map((treeItemData: treeItems) => {
  //     let children = undefined
  //     if (
  //       treeItemData.children &&
  //       treeItemData.children.length > 0 &&
  //       selectedFolder.indexOf(treeItemData.id) === -1
  //     ) {
  //       children = getTreeItemsFromData(treeItemData.children)
  //     }
  //     return (
  //       <TreeItem
  //         className={
  //           (props.folderData && props.folderData.id == treeItemData.id) ||
  //           selectedFolder.indexOf(treeItemData.id) > -1
  //             ? 'disabled'
  //             : ''
  //         }
  //         key={treeItemData.id}
  //         nodeId={treeItemData.id}
  //         label={
  //           treeItemData.name +
  //           (props.folderData && props.folderData.id == treeItemData.id
  //             ? ' [Current]'
  //             : '')
  //         }
  //         children={children}
  //       />
  //     )
  //   })
  // }

  // const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string) => {
  //   if (
  //     (props.folderData && props.folderData.id == nodeIds) ||
  //     selectedFolder.indexOf(nodeIds) > -1
  //   ) {
  //     setSelectedMoveFolder('')
  //   } else {
  //     setSelectedMoveFolder(nodeIds)
  //   }
  // }

  //   const classes = useStyles()
  //   return (
  //     <ThemeProvider theme={lstnBasictheme}>
  //       <Dialog
  //         classes={{ paperWidthSm: classes.root }}
  //         open={props.open}
  //         TransitionComponent={Transition}
  //         keepMounted
  //         onClose={handleClose(false)}
  //         aria-labelledby="alert-dialog-slide-title"
  //         aria-describedby="alert-dialog-slide-description"
  //         data-cy="data-project-move-dialog"
  //       >
  //         <DialogTitle id="alert-dialog-slide-title">
  //           Move Folder/Transcript
  //         </DialogTitle>
  //         <DialogContent>
  //           {folderHierarchy && folderHierarchy.length > 0 && (
  //             <TreeView
  //               defaultCollapseIcon={<FolderOpen />}
  //               defaultExpandIcon={<Folder />}
  //               defaultEndIcon={<Folder />}
  //               defaultExpanded={['root']}
  //               onNodeSelect={handleSelect}
  //               data-cy="data-project-move-tree"
  //             >
  //               {getTreeItemsFromData(folderHierarchy)}
  //             </TreeView>
  //           )}
  //         </DialogContent>
  //         <DialogActions>
  //           <Button
  //             onClick={handleClose(false)}
  //             color="secondary"
  //             variant="outlined"
  //             data-cy="data-project-move-cancel"
  //           >
  //             {t('folder.cancel')}
  //           </Button>
  //           <Button
  //             onClick={handleMove()}
  //             color="primary"
  //             variant="contained"
  //             disabled={!selectedMoveFolder}
  //             data-cy="data-project-move-submit"
  //           >
  //             Move
  //           </Button>
  //         </DialogActions>
  //       </Dialog>
  //     </ThemeProvider>
  //   )
  // const handleOpen = () => props.moveFolderBox()

  const handleCreateNewFolderClose = async () => {
    if (initialValueFolder) {
      setFolderDialog((prevState) => ({ ...prevState, isLoading: true }))
      const folderData = {
        createdAt: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        name: initialValueFolder,
        parentFolderId: filteredfolderData[0] ? filteredfolderData[0].id : '',
        userId: props.combinedProps.userId,
        userRoles: 'owner',
        accountId: currentAccount.id,
        projectId: selectedSpaceId == 'personalSpace' ? '' : selectedSpaceId,
      }
      const addedFolder = await database.collection('folders').add(folderData)
      await database
        .collection('folders')
        .doc(addedFolder.id)
        .set({
          ...folderData,
          id: addedFolder.id,
        })

      let shareUserId: string[] = []
      let shareUserRoles: {} = []

      if (filteredfolderData[0] && filteredfolderData[0].id) {
        shareUserId = filteredfolderData[0].sharedUserIds!
        shareUserRoles = filteredfolderData[0].sharedUserRoles!

        if (
          filteredfolderData[0].userId != props.combinedProps.userId &&
          shareUserId.indexOf(filteredfolderData[0].userId) == -1
        ) {
          // @ts-ignore
          shareUserRoles[filteredfolderData[0].userId] = 'sharedEdit'
          shareUserId.push(filteredfolderData[0].userId)
        }

        if (shareUserId && shareUserId.length > 0) {
          await database
            .collection('folders')
            .doc(addedFolder.id)
            .update({
              sharedUserIds: shareUserId,
              sharedUserRoles: shareUserRoles !== null ? shareUserRoles : {},
            })
        }
      }
      // props.getFolderHeirarchy(props.userId)
      setInitialValueFolder('')
      setFolderDialog((prevState) => ({ ...prevState, isLoading: false }))
      handleFolderDialogOpen()
    }
  }

  const SpaceItem: React.FC<SpaceItemProps> = ({
    name,
    id,
    setSecondDialogOpen,
  }) => {
    return (
      <div
        className={`flex justify-between hover:bg-blue-gray-50 my-1 p-2 rounded-md group cursor-pointer ${
          id == selectedSpaceId ? 'bg-blue-gray-50' : ''
        }`}
        onClick={() => {
          if (!setSecondDialogOpen) return
          setSelectedSpaceId(id)
          setSecondDialogOpen(true)
        }}
        data-cy={`data-${name}`}
      >
        <div className="flex items-center">
          <Folder style={styles} />
          <Typography className="text-base font-medium text-blue-gray-500 ml-2">
            {name}
          </Typography>
        </div>
        <span
          className={` group-hover:flex  ${
            id == selectedSpaceId
              ? 'flex'
              : 'hidden transition-opacity duration-750'
          }`}
        >
          <ChevronRightIcon style={styles} />
        </span>
      </div>
    )
  }
  const RenderItem: React.FC<SpaceItemProps> = ({ name, id, status }) => {
    if (status == 'Folder') {
      return (
        <div
          className={`flex justify-between hover:bg-blue-gray-50 my-1 p-2 rounded-md group cursor-pointer ${
            id == selectedMoveFolder ? 'bg-blue-gray-50' : ''
          }`}
          onClick={() => navigateToFolder(id)}
          data-cy={name}
        >
          <div className="flex items-center">
            <Folder style={styles} />
            <Typography className="text-base font-medium text-blue-gray-500 ml-2">
              {name}
            </Typography>
          </div>
          <span
            className={` group-hover:flex w-[24px] h-[24px] rounded-full p-0 bg-blue-gray-50 shadow-none ${
              id == selectedMoveFolder
                ? 'flex'
                : 'hidden transition-opacity duration-750'
            }`}
            onClick={(event) => {
              event.stopPropagation()
              navigateToFolder(id)
            }}
          >
            <ChevronRightIcon style={styles} />
          </span>
        </div>
      )
    } else {
      return (
        <div
          className={`flex justify-start hover:bg-blue-gray-50 my-1 p-2 rounded-md group cursor-pointer ${
            id == selectedSpaceId ? 'bg-blue-gray-50' : ''
          }`}
        >
          <div className="flex w-full ml-1 items-center">
            <span>
              <img
                src={File}
                className="w-[18px] h-[22px] object-contain"
                alt="img"
              />
            </span>
            <Typography className="text-base font-medium text-blue-gray-500 ml-2">
              {name}
            </Typography>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    setSelectedSpaceId('')
    setSelectedMoveFolder('')
    setSecondDialogOpen(false)
  }, [props.open])

  const navigateToFolder = (id: string) => {
    // if (!navigationStack.includes(selectedMoveFolder)) {
    setNavigationStack((prevStack) => [...prevStack, selectedMoveFolder])
    // }
    setSelectedMoveFolder(id)
  }

  const handleBack = () => {
    if (secondDialogOpen) {
      setNavigationStack((prevStack) => {
        if (prevStack.length === 0) {
          setSecondDialogOpen(false)
          setSelectedMoveFolder('')
          return prevStack
        }
        const newStack = [...prevStack]
        const previousFolder = newStack.pop()
        setSelectedMoveFolder(previousFolder as string)
        return newStack
      })
    }
  }

  const verifyProjectRole = () => {
    const currentProject = filteredProjects.find(
      (project) => project.id == selectedSpaceId,
    )
    if (currentProject && Object.keys(currentProject).length > 0 && roles) {
      const currentUser = currentProject?.users[user.uid!]
      const projectRole = roles[currentUser?.role]?.[roleType.folder]
      return projectRole
    }
  }

  return (
    <Dialog
      placeholder={undefined}
      open={props.open}
      size="sm"
      handler={props.moveFolderBox}
      className="py-4 px-3"
      data-cy="data-project-move-dialog"
    >
      {!secondDialogOpen ? (
        <>
          <DialogHeader placeholder={undefined} className="block">
            <Typography className="text-2xl font-semibold text-blue-gray-900 mb-1">
              Move Folder/Transcript
            </Typography>
            <Typography className="text-sm font-normal text-blue-gray-500 mb-4">
              Select a new location for your files
            </Typography>
          </DialogHeader>
          <DialogBody
            placeholder={undefined}
            // className="max-h-80 h-80 overflow-auto"
          >
            <Typography className="text-sm font-normal text-blue-gray-600 mb-3">
              Your spaces
            </Typography>
            <hr className="bg-blue-gray-50 mb-3" />
            <div className="h-72 overflow-y-auto">
              {accountpersonalSpaceRole?.includes(roleType.PERSONALSPACE) ? (
                <SpaceItem
                  name="Personal Space"
                  id="personalSpace"
                  setSecondDialogOpen={setSecondDialogOpen}
                />
              ) : null}
              {filteredProjects &&
                filteredProjects.map((project) => {
                  return (
                    <SpaceItem
                      key={project.id}
                      name={project.name}
                      id={project.id}
                      setSecondDialogOpen={setSecondDialogOpen}
                    />
                  )
                })}
            </div>
          </DialogBody>
          <DialogFooter placeholder={undefined}>
            <Button
              placeholder={undefined}
              variant="text"
              onClick={() => handleClose(false)}
              className="mr-2 text-blue-gray-900"
              data-cy="data-project-move-cancel"
            >
              <span>Cancel</span>
            </Button>
            <Button
              placeholder={undefined}
              variant="filled"
              className="bg-lstnGreen-500 shadow-sm"
              disabled={!selectedSpaceId}
              onClick={() => setSecondDialogOpen(true)}
              data-cy="data-project-move-submit"
            >
              <span>Move</span>
            </Button>
          </DialogFooter>
        </>
      ) : (
        <>
          <DialogHeader placeholder={undefined} className="block">
            <Typography className="text-2xl font-semibold text-blue-gray-900 mb-1">
              Move to....
            </Typography>
            <Typography className="text-sm font-normal text-blue-gray-500 mb-4">
              Select a folder to show a folder path
            </Typography>
          </DialogHeader>
          <DialogBody
            placeholder={undefined}
            // className="max-h-80 h-80 overflow-auto"
          >
            <Button
              placeholder={undefined}
              disabled={navigationStack.length < 0}
              onClick={() => {
                handleBack()
                // setSelectedSpaceId('')
              }}
              variant="text"
              className="text-sm font-normal capitalize text-blue-gray-600 mb-3 gap-2 p-0 hover:bg-white flex items-center"
            >
              <ArrowBackIcon style={styles} />
              <span>Back</span>
            </Button>
            <hr className="bg-blue-gray-50 mb-3" />
            {selectedSpaceId !== 'personalSpace' &&
              !verifyProjectRole()?.includes(roleType.MOVE) && (
                <div className="flex bg-orange-50 border border-orange-500 rounded-md p-2 items-center">
                  <span>
                    <ErrorOutlineIcon
                      style={{ fontSize: 24, color: '#FF9800' }}
                    />
                  </span>
                  <Typography className="text-sm font-normal text-orange-500 mb-4 ml-2">
                    You do not have permission to move files into this folder
                  </Typography>
                </div>
              )}
            <div className="h-72 overflow-y-auto">
              {spaceData &&
                spaceData.length > 0 &&
                spaceData.map((data) => {
                  return (
                    <RenderItem
                      key={data.id}
                      name={data.name}
                      id={data.id}
                      status={data.status || ''}
                    />
                  )
                })}
            </div>
          </DialogBody>
          <DialogFooter
            placeholder={undefined}
            className="flex justify-between"
          >
            {selectedSpaceId !== 'personalSpace' &&
            !verifyProjectRole()?.includes(roleType.MOVE) ? (
              <CustomTooltip
                className="z-[9999] text-orange-500 bg-orange-50 border border-orange-500 rounded-md flex flex-wrap"
                content={
                  'You do not have permission to create a new folder into this folder'
                }
              >
                <div className="relative">
                  <Button
                    placeholder={undefined}
                    disabled={!verifyProjectRole()?.includes(roleType.MOVE)}
                    variant="text"
                    onClick={handleFolderDialogOpen}
                    className="mr-2 p-0 hover:bg-transparent"
                  >
                    <img
                      src={AddFolder}
                      className="w-[24px] h-[22px] object-contain"
                      alt="img"
                    />
                  </Button>
                </div>
              </CustomTooltip>
            ) : (
              <Button
                placeholder={undefined}
                variant="text"
                onClick={handleFolderDialogOpen}
                className="mr-2 p-0 hover:bg-transparent"
              >
                <img
                  src={AddFolder}
                  className="w-[24px] h-[22px] object-contain"
                  alt="img"
                />
              </Button>
            )}
            <div>
              <Button
                placeholder={undefined}
                variant="text"
                onClick={() => handleClose(false)}
                className="mr-2 text-blue-gray-900"
                data-cy="data-project-move-cancel"
              >
                <span>Cancel</span>
              </Button>
              <Button
                placeholder={undefined}
                variant="filled"
                className="bg-lstnGreen-500 shadow-sm"
                disabled={
                  selectedSpaceId !== 'personalSpace' &&
                  !verifyProjectRole()?.includes(roleType.MOVE)
                }
                onClick={handleMove}
                data-cy="data-project-move-submit"
              >
                <span>Move</span>
              </Button>
            </div>
          </DialogFooter>
        </>
      )}
      <Dialog
        placeholder={undefined}
        open={folderDialog.isOpen}
        size="xs"
        handler={handleFolderDialogOpen}
        className="py-4 px-3"
      >
        <div className="flex items-center justify-between">
          <DialogHeader
            placeholder={undefined}
            className="flex flex-col items-start"
          >
            {' '}
            <Typography className="mb-1" variant="h4">
              Create Folder
            </Typography>
          </DialogHeader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5"
            onClick={handleFolderDialogOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody placeholder={undefined}>
          <div className="grid gap-6">
            <Input
              // size="md"
              type="text"
              placeholder="Folder Name"
              name="folderName"
              className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              value={initialValueFolder}
              onChange={(e) => setInitialValueFolder(e.target.value)}
              crossOrigin={undefined}
              data-cy="data-account-company-name"
            />
          </div>
        </DialogBody>
        <DialogFooter placeholder={undefined} className="space-x-2">
          <Button
            placeholder={undefined}
            variant="text"
            color="gray"
            onClick={handleFolderDialogOpen}
          >
            cancel
          </Button>
          <Button
            placeholder={undefined}
            variant="gradient"
            color="gray"
            onClick={handleCreateNewFolderClose}
            disabled={!initialValueFolder}
            className="flex items-center"
          >
            {folderDialog.isLoading ? (
              <CircularProgress size={16} color="inherit" className="mr-2" />
            ) : (
              ''
            )}
            create
          </Button>
        </DialogFooter>
      </Dialog>
    </Dialog>
  )
}
