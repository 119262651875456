import React from 'react'

import { ILayoutProps } from './Dropzone'

const Layout = (props: ILayoutProps) => {
  const {
    input,
    previews,
    submitButton,
    uploadAutomatically,
    dropzoneProps,
    files,
    extra: { maxFiles },
  } = props

  return (
    <div {...dropzoneProps}>
      {previews}

      {files.length < maxFiles && input}

      {files.length > 0 && submitButton}

      {files.length > 0 && uploadAutomatically}
    </div>
  )
}

export default Layout
