import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
// import { connect } from 'react-redux'
// import { compose } from 'redux'
// import { firestoreConnect, withFirestore } from 'react-redux-firebase'
// import { Dispatch } from 'redux'
// import { withHandlers } from 'recompose'
import { TransProps } from 'react-i18next'
import { IFolders, ITranscript, IUser } from '../interfaces'
// import {  getFolder, folderHierarchy } from '../store/actions/transcriptActions'
// import { fetchUserStatus } from "../store/actions/userStatus"
import TranscriptsList from '../components/TranscriptsList'
// import { IRootState } from '../store/reducers/rootReducer'
import StartTourDialog from '../components/onBoarding/StartTourDialog'
import firebase from 'firebase/compat/app'
import { database, functionsV2 } from '../firebaseApp'
import '../css/Transcripts.css'
import UploadButtonCheck from './../components/RolesAccessComponents/UploadButtonCheck'
import { useAppDispatch, useAppSelector } from '../store/store'
// import { fetchUserById } from "../store/features/userSlice"
// import { customEvent } from "../utils/customHooks"
// import ReactGA from "react-ga4";
import {
  fetchFolderHeirarchy,
  getFolderData,
} from '../store/features/folderSlice'
import {
  resetTranscripts,
  resetState,
} from '../store/features/transcript/transcriptsSlice'
import { resetParagraph } from '../store/features/paragraphSlice'
import SplashScreen from '../components/SplashScreen'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@material-tailwind/react'
import { fetchTranscriptSubs } from '../store/features/transcript/helper'
import UpgradeModal from '../components/UpgradeModal'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Toast from '../components/Toast/Toast'
import { setCurrentProject } from '../store/features/projectSlice'
import { IUserStatus } from '../interfaces/IUser'
import { customEvent } from '../utils/customHooks'
import { fetchAllAccounts } from '../store/features/accountSlice'
import { resetReplies } from '../store/features/conversationSlice'
import { roleType } from '../enums'

interface IStateProps {
  transcripts: ITranscript[]
  user: firebase.UserInfo
  // getUserStatus: (userId: string) => void
  userData: IUser[]
  // updateUser: (id: string, data: ) => Promise<void>
  userStatus: IUserStatus | undefined
  getFolderData: (folder: IFolders | undefined) => void
  resetState: () => void
  folderData: IFolders
  // rolesValue?:
}

interface IUpdateUserData {
  onboardingSteps?: {
    uploadVideos?: boolean
    transcriptionEdit?: boolean
  }
  preferredLanguage?: string
  showWarning?: boolean
}

interface IState {
  file?: File
  uploadingOnBoarding: boolean
  folderHierarchy: IFolders[]
  loading: boolean
  prevId: string | undefined
}
const Transcripts: React.FC<
  // RouteComponentProps<{ id: string }> &
  IStateProps &
    //@ts-ignore
    TransProps &
    //@ts-ignore
    TypographyProps
> = (props) => {
  const [state, setState] = useState<IState>({
    uploadingOnBoarding: false,
    folderHierarchy: [],
    loading: true,
    prevId: '',
  })
  const [forceRender, setForceRender] = useState(false)
  const [UpgradeOpen, setUpgradeOpen] = useState(false)
  const [upgradeNotification, setUpgrageNotification] = useState(false)
  const dispatch = useAppDispatch()
  const { userStatus, user, userData } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { folderHierarchy } = useAppSelector((store) => store.folderHierarchy)
  const { folderData } = useAppSelector((store) => store.folder)
  const { status, allFolderStatus, transcriptRows } = useAppSelector(
    (store) => store.transcriptSlice,
  )
  const { projects, currentProject, roles } = useAppSelector(
    (store) => store.project,
  )

  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  // Conditionally set the initial state of `open`
  const [open, setOpen] = useState(
    userData.onboardingSteps &&
      !userData.onboardingSteps.uploadVideos &&
      !state.uploadingOnBoarding,
  )
  const uploadref = useRef<HTMLElement>(null)
  const verifyProjectRole = (roleToSpecify: string) => {
    if (currentProject && Object.keys(roles).length > 0) {
      const currentUser = currentProject.users[user.uid!]
      const projectRole = roles[currentUser?.role]?.[roleToSpecify]
      return projectRole
    }
  }
  useEffect(() => {
    if (params?.id) {
      if (location.pathname.includes('/project/')) {
        if (location.pathname.includes('/project/folder')) {
          dispatch(resetTranscripts())
          dispatch(getFolderData(params?.id))
        } else {
          dispatch(getFolderData(undefined))
        }
      } else {
        dispatch(resetTranscripts())
        dispatch(getFolderData(params?.id))
      }
    } else {
      dispatch(getFolderData(undefined))
    }
    dispatch(resetTranscripts())
    dispatch(resetState())
    dispatch(resetParagraph())
    dispatch(resetReplies())
  }, [dispatch, params?.id, location])

  useEffect(() => {
    fetchTranscriptSubs({ dispatch })
  }, [dispatch, user.uid])

  useEffect(() => {
    setState({ ...state, loading: status == 'loading' ? true : false })
  }, [status, allFolderStatus])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (user && user.uid) {
        // props.getUserStatus(props.user.uid)
        // dispatch(fetchFolderHeirarchy(user.uid))
        dispatch(fetchFolderHeirarchy(user.uid))
      }

      // Run upload video onboarding
      // if (userData && userData.onboardingSteps && !userData.onboardingSteps.uploadVideos) {
      //   runUploadOnboarding()
      // }

      // getFolderHeirarchy();

      const folderId = params?.id || ''
      if (folderId && !location.pathname.includes('/project/')) {
        // let folder = await database.collection('folders').doc(folderId).get()
        // props.getFolderData({ id: folderId, ...folder.data() } as IFolders)
        dispatch(getFolderData(folderId))
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }))
        }, 2000)
      } else {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }))
      }
    }

    fetchData()
  }, [user && user.uid, params, userData, forceRender, getFolderData])

  // check invitation data from localStorage as set by page `src/pages/InvitationProceed.tsx`
  useEffect(() => {
    const inviteId = localStorage.getItem('inviteId')
    const inviteValidityCode = localStorage.getItem('inviteValidityCode')
    console.log('inviteId', inviteId)
    console.log('inviteValidityCode', inviteValidityCode)
    const acceptInviteAndProceed = async (): Promise<void> => {
      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/accounts/acceptInvite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: {
              inviteId,
              validityCode: parseInt(inviteValidityCode!, 10),
            },
          }),
        },
      )
      const data = await response.json()
      console.log('^^^ data', data)
      if (data && data.status === 'success') {
        Toast({
          title: t('accountSettings.inviteAccepted'),
          text: t('accountSettings.accountJoined'),
          variant: 'success',
          options: { autoClose: 5000 },
        })
        localStorage.removeItem('inviteId')
        localStorage.removeItem('inviteValidityCode')
        dispatch(fetchAllAccounts({ dispatch }))
      }
    }

    if (inviteId && inviteValidityCode && user) {
      acceptInviteAndProceed()
    }
  }, [user])

  // const getFolderHeirarchy = useCallback(async () => {
  //   // let folders: IFolders[] = []
  //   // let userFolders = await database.collection("folders").where("userId", "==", userId).get()

  //   // for (let i = 0; i < userFolders.docs.length; i++) {
  //   //   let doc:  = userFolders.docs[i]
  //   //   folders.push({ ...{ id: doc.id }, ...doc.data() })
  //   // }

  //   // userFolders = await database.collection("folders").where("sharedUserIds", "array-contains", userId).get()
  //   // for (let i = 0; i < userFolders.docs.length; i++) {
  //   //   let doc:  = userFolders.docs[i]
  //   //   folders.push({ ...{ id: doc.id }, ...doc.data() })
  //   // }

  //   // function list_to_tree(list: IFolders[]) {
  //   //   var map = {},
  //   //     node,
  //   //     roots = [],
  //   //     i

  //   //   for (i = 0; i < list.length; i += 1) {
  //   //     map[list[i].id] = i // initialize the map
  //   //     list[i].children = [] // initialize the children
  //   //   }

  //   //   for (i = 0; i < list.length; i += 1) {
  //   //     node = list[i]
  //   //     if (node.archive) {
  //   //       // Handle archived nodes if needed
  //   //     } else {
  //   //       if (node.parentFolderId !== "") {
  //   //         const parentIndex = map[node.parentFolderId]
  //   //         if (parentIndex !== undefined) {
  //   //           const parent = list[parentIndex]
  //   //           if (parent && parent.children) {
  //   //             parent.children.push(node)
  //   //           }
  //   //         } else {
  //   //           roots.push(node)
  //   //         }
  //   //       } else {
  //   //         roots.push(node)
  //   //       }
  //   //     }
  //   //   }
  //   //   return roots
  //   // }

  //   // folders = list_to_tree(folders)

  //   // dispatch(getFolderData(props.match?.params?.id))
  //   setState((prevState) => ({
  //     ...prevState,
  //     folderHierarchy: folderHierarchy,
  //   }))
  // }, [])

  useEffect(() => {
    const ln = localStorage.getItem('lstn-display-lng')

    if (userData && ln && userData.preferredLanguage !== ln) {
      updateUserData(user.uid, {
        preferredLanguage: ln,
      })

      const updateUser = functionsV2.httpsCallable('updateUser')

      try {
        updateUser({
          ...userData,
          preferredLanguage: ln,
          userId: user.uid,
        })
      } catch (error) {
        // Handle error
      }
    }

    if (
      userData &&
      userData.onboardingSteps &&
      !userData.onboardingSteps.uploadVideos &&
      !state.uploadingOnBoarding
    ) {
      runUploadOnboarding()
    }

    if (
      params.id !== state.prevId &&
      !location.pathname.includes('/project/')
    ) {
      setState({ ...state, loading: true, prevId: params.id })

      const fetchFolderData = async (): Promise<void> => {
        const folderId = params.id || ''

        if (folderId) {
          try {
            // const folder = await database.collection('folders').doc(folderId).get()
            // props.getFolderData({ id: folderId, ...folder.data() } as IFolders)
            dispatch(getFolderData(folderId))
          } catch (error) {
            // Handle error
          }
        } else {
          // props.getFolderData(undefined)
          dispatch(getFolderData(undefined))
        }

        setTimeout(() => {
          setState({ ...state, loading: false })
        }, 1000)
      }

      fetchFolderData()
    }
  }, [props])

  useEffect(() => {
    if (
      location.pathname.includes('/project/') &&
      params.id &&
      projects &&
      projects.length > 0 &&
      (Object.keys(currentProject).length === 0 ||
        currentProject.id !== params.id)
    ) {
      const project = projects.find((p) => p.id === params.id)
      if (project && project.users[user.uid!]) {
        dispatch(setCurrentProject(project))
      }
    }
  }, [
    currentProject,
    dispatch,
    location.pathname,
    params.id,
    projects,
    props,
    state.loading,
    user.uid,
  ])

  useEffect(() => {
    if (
      userData &&
      userData.onboardingSteps &&
      !userData.onboardingSteps.uploadVideos &&
      !state.uploadingOnBoarding
    ) {
      runUploadOnboarding()
    }
  }, [userData, userData.onboardingSteps])

  const runUploadOnboarding = useCallback(() => {
    setState({
      ...state,
      uploadingOnBoarding: true,
    })
  }, [])

  const closeOnboarding = useCallback(async () => {
    const onboardingSteps = {
      ...(userData.onboardingSteps || {}),
    }
    onboardingSteps.uploadVideos = true
    await updateUserData(user && user.uid, {
      onboardingSteps,
    })
    setState({
      ...state,
      uploadingOnBoarding: false,
    })
    setOpen(false)
  }, [userData, updateUserData, user.uid])

  const handleFileSelected = useCallback((file: File) => {
    setState({
      ...state,
      file,
    })
  }, [])

  const componentForceUpdate = useCallback(() => {
    setForceRender((prevForceRender) => !prevForceRender)
  }, [])

  async function updateUserData(
    id: string,
    data: IUpdateUserData,
  ): Promise<void> {
    try {
      const userRef = database.collection(`users`).doc(id)
      await userRef.update({ ...data })
      // console.log('&&&&',data);
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleTranscriptIdSelected = useCallback(
    (transcriptId: string) => {
      navigate(`/transcript/${transcriptId}`)
    },
    [navigate],
  )

  const handleFolderIdSelected = useCallback(
    (folderId: string) => {
      if (location.pathname.includes('/project/')) {
        navigate(`/project/folder/${folderId}`)
      } else {
        navigate(`/transcript/folder/${folderId}`)
      }
    },
    [navigate],
  )
  const checkUploadStatus = useMemo(() => {
    if (userStatus && userStatus.status) {
      return userStatus
    }
    return null
  }, [userStatus])
  const handleUpgrade = (): void => {
    if (!UpgradeOpen) {
      customEvent('billing_upgrade_modal_opened', {
        category: 'accounts',
        accountId: currentAccount.id,
        user_userId: user.uid,
        actionSource: 'account-limit-reached',
      })
    }
    setUpgradeOpen(!UpgradeOpen)
  }
  const checkTranscriptDuration = (): void => {
    if (!upgradeNotification) {
      setUpgrageNotification(true)
    }
  }

  const { pathname } = location
  if (
    allFolderStatus == 'idle' ||
    (pathname.includes('/project/') && currentProject && !currentProject.users)
  ) {
    return <SplashScreen />
  }

  return (
    <main data-cy="project-dashboard">
      {upgradeNotification ||
      (checkUploadStatus && checkUploadStatus.status !== 'active') ||
      (checkUploadStatus &&
        Number(checkUploadStatus.remainingSeconds) <= 120) ||
      (checkUploadStatus &&
        Number(checkUploadStatus?.additionalSeconds) <= 0) ? (
        <div className="bg-lstnGreen-50 flex justify-between items-center mx-8 mt-2 px-6 p-4 max-w-full transition-all duration-100">
          <Typography
            placeholder={undefined}
            className="text-blue-gray-700 text-base font-normal"
          >
            {t('layout.notification')}
          </Typography>
          <Button
            variant="outlined"
            size="sm"
            onClick={handleUpgrade}
            className="text-lstnGreen-500 border-lstnGreen-500 outline-none"
            placeholder={'upgrade'}
          >
            {t('modal.upgrade')}
          </Button>
        </div>
      ) : (
        ''
      )}
      <div className="transcripts-container">
        {user && user.uid ? (
          <>
            {(pathname.includes('/project/') &&
              currentProject &&
              currentProject.users &&
              currentProject.users[user.uid] &&
              verifyProjectRole('transcript').includes(roleType.ADD)) ||
            !pathname.includes('/project/') ? (
              <div
                className="transcripts-upload-area"
                data-cy="data-project-transcripts-upload-area"
              >
                {pathname.includes('/project/') ? (
                  <Typography
                    placeholder={undefined}
                    className="text-2xl text-blue-gray-900 font-medium mb-4"
                  >
                    {t('projects.uploadIn')} {currentProject.name}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      placeholder={undefined}
                      className="text-2xl text-blue-gray-900 font-medium mb-4"
                    >
                      {transcriptRows.length > 0
                        ? t('transcripts.uploadTitle')
                        : t('transcripts.uploadTitle2')}
                    </Typography>
                  </>
                )}
                {userStatus && userData && (
                  <UploadButtonCheck
                    fileSelected={handleFileSelected}
                    userId={user.uid}
                    userStatus={userStatus}
                    userData={userData}
                    updateUser={updateUserData}
                    folderData={folderData}
                    uploadref={uploadref}
                    checkTranscriptDuration={checkTranscriptDuration}
                  />
                )}
              </div>
            ) : null}
            {/* {!pathname.includes('/project/') && ( */}
            <>
              <StartTourDialog open={open} close={closeOnboarding} />
              <TranscriptsList
                userId={user.uid}
                handleTranscriptIdSelected={handleTranscriptIdSelected}
                handleFolderIdSelected={handleFolderIdSelected}
                folderData={folderData}
                componentForceUpdate={componentForceUpdate}
                folderHierarchy={folderHierarchy}
                // getFolderHeirarchy={getFolderHeirarchy}
                loading={state.loading}
                location={props.match}
                uploadref={uploadref}
              />
            </>
            {/* )} */}
          </>
        ) : null}
      </div>
      <UpgradeModal UpgradeOpen={UpgradeOpen} handleUpgrade={handleUpgrade} />
    </main>
  )
}

// const mapStateToProps = (state: IRootState) => {
//   let rolesValue = {}
//   if (state && state.firestore && state.firestore.data && state.firestore.data.roles) {
//     rolesValue = state.firestore.data.roles[Object.keys(state.firestore.data.roles)[0]]
//   }

//   return {
//     transcripts: state.firestore.data.transcripts,
//     user: state.firebase.auth,
//     userData: state.firestore.ordered.users,
//     userStatus: state.userStatus,
//     folderData: state.transcript.folder ? state.transcript.folder : undefined,
//     rolesValue,
//   }
// }

// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     // getUserStatus: (userId: string) => dispatch(fetchUserStatus.request(userId)),
//     // resetState: () => dispatch(resetState()),
//     getFolderData: (folder: IFolders) => dispatch(getFolder(folder)),
//     folderHierarchy: (folderHie: IFolders[]) => dispatch(folderHierarchy(folderHie)),
//   }
// }
export default Transcripts
// export default compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   firestoreConnect((props: IStateProps) => [
//     {
//       collection: 'users',
//       doc: props.user.uid,
//     },
//     {
//       collection: 'transcripts',
//       storeAs: 'allTranscripts',
//       where: [['userId', '==', props.user.uid]],
//     },
//     {
//       collection: 'transcripts',
//       storeAs: 'allSharedTranscripts',
//       where: [['sharedUserIds', 'array-contains', props.user.uid]],
//     },
//     {
//       collection: 'folders',
//       storeAs: 'allFolders',
//       where: [['userId', '==', props.user.uid]],
//     },
//     {
//       collection: 'folders',
//       storeAs: 'allSharedFolders',
//       where: [['sharedUserIds', 'array-contains', props.user.uid]],
//     },
//   ]),
//   withHandlers({
//     updateUser: (props: ) => async (id: string, data: ) => {
//       await props.firestore.update(`users/${id}`, { ...data })
//     },
//   }),
//   withTranslation(),
//   withFirestore,
// )(Transcripts)
