import React, { useCallback, useEffect, useRef, useState } from 'react'
import { customEvent } from '../utils/customHooks'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'
import 'firebaseui/dist/firebaseui.css'
import '../css/SignUp.css'
import firebase from '../firebaseApp'
import Typography from './Typography/Typography'
import LanguageSelector from './Signup/Select'
import * as Yup from 'yup'
import i18n from '../i18n'
import ReactGA from 'react-ga4'
import Sidebar from './Signup/Sidebar'
import Toast from './Toast/Toast'
import SplashScreen from './SplashScreen'
import Button from './Button/TailwindButton'
import LoginForm from './Signup/LoginForm'
// import { useNavigate } from 'react-router'

const trackingCode: string | undefined = process.env.REACT_APP_GA4_PROPERTY_ID
type Language = 'en' | 'fr' | 'es'
if (trackingCode) {
  ReactGA.initialize([
    {
      trackingId: trackingCode,
    },
  ])
}
const Index: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [inviteEmail, setInviteEmail] = useState<string>('')

  const passwordInputRef = useRef<HTMLInputElement | null>(null)
  const inviteId = localStorage.getItem('inviteId')
  const { t } = useTranslation()
  // const navigate = useNavigate()

  const SignUpFormSchema = Yup.object().shape({
    email:
      inviteEmail !== ''
        ? Yup.string()
            .email('Invalid email')
            .test(
              'email-match',
              'Oops! email address does not match',
              function (value) {
                return value === inviteEmail
              },
            )
            .required('Email is required')
        : Yup.string().email().required('email is required'),
    password: Yup.string()
      .required()
      .min(8, 'Your password has to have at least 8 character'),
  })
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.uid) {
        setLoading(true)
      }
    })
  }, [])
  const handleLanguageSelect = async (value: string) => {
    const ln = value || ''
    // setDisplayLanguage(ln as Language)
    i18n.changeLanguage(ln as Language)
    localStorage.setItem('lstn-display-lng', ln as Language)
  }

  const handleSignIn = useCallback(
    async (
      values: { email: string; password: string },
      formikHelpers: FormikHelpers<{ email: string; password: string }>,
    ) => {
      const { email, password } = values
      if (email && password) {
        setLoading(true)
        customEvent('log_in_requested', {
          category: 'auth',
          authAttribute: 'email_auth',
        })
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((userCredential) => {
            const user = userCredential.user
            if (user) {
              customEvent('log_in', {
                category: 'auth',
                authAttribute: 'email_auth',
                user_userId: user.uid,
              })
            }
            // ...
            if (inviteId) {
              localStorage.removeItem('inviteId')
              localStorage.removeItem('inviteValidityCode')
            }
            // navigate('/')
            // setLoading(false)
            setLoading(true)
          })
          .catch((error) => {
            const errorMessage = error.message
            console.error('An error occurred:', errorMessage)
            // console.log(errorMessage)
            formikHelpers.resetForm({ values: { ...values, password: '' } })
            setLoading(false)
            Toast({
              title: t && t('auth.toast.title'),
              text: t && t('auth.toast.incorrectPasswordText'),
              variant: 'error',
            })
          })
      }
    },
    [],
  )
  const handleSignGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    customEvent('log_in_requested', {
      category: 'auth',
      authAttribute: 'google_auth',
    })
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const user = result.user
        if (user) {
          customEvent('log_in', {
            category: 'auth',
            authAttribute: 'google_auth',
            user_userId: user.uid,
          })
        }
      })
      .catch((error) => {
        console.log(error.message)
        // ...
      })
  }

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <div className="flex ">
          <div className="hidden h-screen bg-gradient-to-t from-[#dcfcdfd7] to-[#F3F8FF] md:flex lg:w-1/3 md:w-2/5 flex-col justify-between">
            <Sidebar />
          </div>
          <div className="w-full pt-20 lg:w-3/4 md:w-3/5 p-6 h-auto md:pt-4 lg:pt-4 xl:pt-4 flex justify-center items-center relative ">
            <div className="absolute right-10 top-8">
              <LanguageSelector onSelect={handleLanguageSelect} />
            </div>
            <div className="md:w-1/2 mx-auto w-full sm:w-96 lg:w-2/5 xl:w-2/5 md:h-auto ">
              <Typography className="p-2 text-center text-3xl font-semibold text-lstnBlueGray-900">
                {t && t('auth.logInAccount')}
              </Typography>
              <div className="flex flex-col items-center gap-2 2xl:gap-6">
                <Button
                  color="white"
                  className="!border !border-blue-gray-50 bg-white flex items-center text-sm font-bold text-lstnBlueGray-900 gap-3 w-full justify-center my-2"
                  onClick={handleSignGoogle}
                >
                  <img
                    src="https://docs.material-tailwind.com/icons/google.svg"
                    alt="metamask"
                    className="h-4 w-4"
                  />
                  {t && t('auth.signInwithGoogle')}
                </Button>
                {t('auth.or')}
              </div>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={handleSignIn}
                validationSchema={SignUpFormSchema}
                validateOnMount={true}
              >
                {({
                  values,
                  errors,
                  // isValid,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <LoginForm
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    passwordInputRef={passwordInputRef}
                    touched={touched}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    inviteId={inviteId}
                    setFieldValue={setFieldValue}
                    setInviteEmail={setInviteEmail}
                  />
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Index
