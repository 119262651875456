import React, { useState, useEffect, useContext } from 'react'
import { roleType } from '../../enums'
import { ITranscriptRow, IUserFeatures } from '../../interfaces'
import { ExportValues } from '../ExportMenu/ExportMenu'
import ActionItems from '../Transcripts/ActionPanelItems'
import { RolesContext } from './../../context/ContextProvider'
import { useAppSelector } from '../../store/store'
import { IUserStatus } from '../../interfaces/IUser'

interface userStatus {
  fetchStatusFailure: boolean
  fetchStatusProgress: boolean
  fetchStatusSuccess: boolean
  loading: string
  userStatus: IUserStatus
}
interface IProps {
  checkedRows: boolean
  checked: boolean
  onDelete: () => void
  onExport: (exportData: ExportValues) => void
  onRename: () => void
  onNewFolder: () => void
  userStatus: userStatus
  transcriptIds: string[]
  userFeatures?: IUserFeatures
  handleMoveFolder: () => void
  checkedRowsData: ITranscriptRow[]
  getItemsIds: (folderId: string) => void
  userId: string
  onBulkExport: (transcriptIds: string[], data: ExportValues) => void
  getFolderHeirarchy: (userId: string) => void
  location?: string
  openPopup?: (data: ITranscriptRow[]) => void
  handleSearchChange: () => void
}

const ActionArrange = (props: IProps) => {
  const [editFile, setEditFile] = useState(false)
  const [moveEnable, setMoveEnable] = useState(false)
  const [deleteEnable, setDeleteEnable] = useState(false)
  const [shareEnable, setShareEnable] = useState(false)
  const [folderCreateRole, setFolderCreateRole] = useState<string[]>([])
  const { checked, checkedRowsData, checkedRows } = props
  const { setCreateTranscript } = useContext(RolesContext)
  const [transcriptRoles, setTranscriptRoles] = useState<string[]>([])
  const { accountRoles, user } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const currentAccountRole = currentAccount.users[user.uid!]
  const { currentProject, roles } = useAppSelector((store) => store.project)
  useEffect(() => {
    // const checkCreateFolder = async () => {
    //   const role = roles ? roles['fileMgmt'] : {}

    //   if (props.location) {
    //     const folderId = props.location.split('/')[3]
    //     if (folderId) {
    //       const folderData = (
    //         await database.collection('folders').doc(folderId).get()
    //       ).data()

    //       if (folderData) {
    //         if (folderData.userId === props.userId) {
    //           setFolderCreateRole(role[roleType.OWNER])
    //           setCreateTranscript(role[roleType.OWNER])
    //         } else if (
    //           folderData.sharedUserRoles &&
    //           folderData.sharedUserRoles[props.userId] === roleType.SHAREDEDIT
    //         ) {
    //           setFolderCreateRole(role[roleType.SHAREDEDIT])
    //           setCreateTranscript(role[roleType.SHAREDEDIT])
    //         } else {
    //           setFolderCreateRole(role[roleType.SHAREDREAD])
    //           setCreateTranscript(role[roleType.SHAREDREAD])
    //         }
    //       }
    //     }
    //   }
    // }

    // checkCreateFolder()
    if (accountRoles) {
      setFolderCreateRole(accountRoles[currentAccountRole?.role]?.['folder'])
      setCreateTranscript(
        accountRoles[currentAccountRole?.role]?.['transcript'],
      )
      setTranscriptRoles(accountRoles[currentAccountRole?.role]?.['transcript'])
    }
  }, [accountRoles, currentAccountRole])
  const verifyProjectRole = (roleToSpecify: string) => {
    if (currentProject && roles) {
      const currentUser = currentProject.users[user.uid!]
      const projectRole = roles[currentUser?.role]?.[roleToSpecify]
      return projectRole
    }
  }
  useEffect(() => {
    if ((checkedRows || checked) && checkedRowsData) {
      // For Move Role check in files
      for (const file of checkedRowsData) {
        if (
          (file.roles && file.roles?.includes(roleType.MOVE)) ||
          verifyProjectRole('transcript').includes(roleType.MOVE) ||
          (currentAccountRole?.role == roleType.GUEST &&
            file &&
            verifyProjectRole('transcript').includes(roleType.FILEEDIT))
        ) {
          setMoveEnable(true)
        } else {
          setMoveEnable(false)
          break
        }
      }

      // For Edit Role check in files
      for (const file of checkedRowsData) {
        if (
          (file.roles && file.roles?.includes(roleType.EDIT)) ||
          verifyProjectRole('transcript').includes(roleType.EDIT) ||
          (currentAccountRole?.role == roleType.GUEST &&
            file &&
            verifyProjectRole('transcript').includes(roleType.FILEEDIT))
        ) {
          setEditFile(true)
        } else {
          setEditFile(false)
          break
        }
      }

      // For Delete Role check in files
      for (const file of checkedRowsData) {
        // console.log(file.roles, 'file.roles')
        if (
          (file.roles && file.roles?.includes(roleType.DELETE)) ||
          verifyProjectRole('transcript').includes(roleType.DELETE) ||
          (currentAccountRole?.role == roleType.GUEST &&
            file &&
            verifyProjectRole('transcript').includes(roleType.FILEEDIT))
        ) {
          setDeleteEnable(true)
        } else {
          setDeleteEnable(false)
          break
        }
      }

      // For SHARE Role check in files
      for (const file of checkedRowsData) {
        if (file.roles && file.roles?.includes(roleType.SHARE)) {
          setShareEnable(true)
        } else {
          setShareEnable(false)
          break
        }
      }
    }
  }, [checked, checkedRows, checkedRowsData])

  return (
    <ActionItems
      {...props}
      folderCreateRole={folderCreateRole}
      transcriptRoles={transcriptRoles}
      share={shareEnable}
      move={moveEnable}
      edit={editFile}
      deleteCheck={deleteEnable}
    />
  )
}

export default ActionArrange
