import React from 'react'
// import Button from '@material-ui/core/Button'
// import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import Toast from '../../components/Toast/Toast'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import Slide from '@material-ui/core/Slide'
// import { TransitionProps } from '@material-ui/core/transitions'
import '../../css/uploader.css'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'

interface IProps {
  headerText?: string
  delBtn?: true | false
  copyBtn?: boolean
  bodyText?: string
  primaryLabel?: string
  open: boolean
  onClose: (value?: boolean) => void
  noSecondaryAction?: boolean
  cy: string
}

// const Transition = React.forwardRef<unknown, TransitionProps>(
//   function Transition(props, ref) {
//     // @ts-ignore
//     return <Slide direction="up" ref={ref} {...props} />
//   },
// )

export default function ConfirmationDialog({
  headerText,
  delBtn,
  copyBtn,
  bodyText,
  primaryLabel,
  open,
  onClose,
  noSecondaryAction,
  cy,
}: IProps) {
  const { t } = useTranslation()
  const handleClose = (status?: boolean) => {
    onClose(status)
  }

  const handleCopy = () => {
    if (typeof bodyText === 'string') {
      navigator.clipboard.writeText(bodyText)
      Toast({ text: t && t('transcripts.copyConfirm'), variant: 'success' })
    }
  }

  return (
    <div className="ConfirmationDialogue">
      <Dialog
        placeholder={undefined}
        open={open}
        size="sm"
        handler={() => handleClose(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        data-cy={`data-project-${cy}-dialog`}
        className="py-4 px-3"
      >
        <DialogHeader
          placeholder={undefined}
          id="alert-dialog-slide-title"
          className="text-2xl font-semibold text-blue-gray-900"
        >
          {headerText}
        </DialogHeader>
        <DialogBody placeholder={undefined}>
          <Typography
            id="alert-dialog-slide-description"
            className="text-lg font-normal text-blue-gray-500"
          >
            {bodyText}
          </Typography>
        </DialogBody>
        <DialogFooter placeholder={undefined}>
          {!noSecondaryAction && (
            <Button
              placeholder={undefined}
              onClick={() => handleClose(false)}
              className="black"
              data-cy={`data-project-${cy}-cancel`}
              variant="text"
            >
              {t('renameDialog.cancel')}
            </Button>
          )}
          {delBtn && (
            <Button
              placeholder={undefined}
              onClick={() => handleClose(true)}
              className="bg-lstnGreen-500 shadow-sm text-white"
              variant="filled"
              data-cy={`data-project-${cy}-submit`}
            >
              {primaryLabel}
            </Button>
          )}
          {copyBtn && (
            <Button
              placeholder={undefined}
              onClick={handleCopy}
              // color="secondary"
              variant="outlined"
            >
              {primaryLabel}
            </Button>
          )}
        </DialogFooter>
      </Dialog>
    </div>
  )
}
