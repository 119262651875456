import { database } from '../firebaseApp'
import firebase from 'firebase/compat/app'
import { IFolders, ITranscript } from '../interfaces'
import { roleType } from '../enums'

export const foldersDataPropagation = async (
  folderId: string,
  operation: {
    shareduserIds?: string[]
    userId?: string
    sharedUserRoles?: any
    delete?: boolean
  },
  Transcripts: ITranscript[],
  Folders: IFolders[],
  projectId?: string,
) => {
  if (folderId) {
    const transcripts = Transcripts.filter(
      (d) =>
        d.folderId === folderId &&
        (d.userId === operation.userId ||
          (d.sharedUserRoles &&
            d.sharedUserRoles[operation.userId!] === roleType.SHAREDEDIT)),
    )
    transcripts.map(async (file) => {
      const ref = database.collection('transcripts').doc(file.id)
      if (operation.delete) {
        ref.update({
          archive: true,
        })
      } else {
        await ref.update({
          userId: operation.userId,
          sharedUserIds: operation.shareduserIds,
          sharedUserRoles: operation.sharedUserRoles,
          projectId: projectId,
        })
      }
    })

    const folders = Folders.filter(
      (d) =>
        (d.userId === operation.userId ||
          (d.sharedUserRoles &&
            d.sharedUserRoles[operation.userId!] === roleType.SHAREDEDIT)) &&
        d.parentFolderId === folderId,
    )
    folders.map(async (file) => {
      const ref = database.collection('folders').doc(file.id)
      if (operation.delete) {
        ref.update({
          archive: true,
        })
        foldersDataPropagation(
          file.id!,
          { userId: operation.userId, delete: operation.delete },
          Transcripts,
          Folders,
          projectId,
        )
      } else {
        await ref.update({
          userId: operation.userId,
          sharedUserIds: operation.shareduserIds,
          sharedUserRoles: operation.sharedUserRoles,
          projectId: projectId,
        })
        foldersDataPropagation(
          file.id!,
          {
            userId: operation.userId,
            sharedUserRoles: operation.sharedUserRoles,
            shareduserIds: operation.shareduserIds,
          },
          Transcripts,
          Folders,
          projectId,
        )
      }
    })
    if (folders.length < 1) {
      return true
    }
  }

  return true
}

export const shareChildFolders = async (
  folderId: string,
  shareUserId: string,
  userId: string,
  role: string,
  Transcripts: ITranscript[],
  Folders: IFolders[],
) => {
  if (folderId) {
    try {
      const transcripts = Transcripts.filter(
        (d) =>
          (d.userId === userId || d.sharedUserIds?.includes(userId)) &&
          d.folderId === folderId,
      )
      transcripts.map(async (file) => {
        const ref = database.collection('transcripts').doc(file.id)
        let existingShareUserIds = file.sharedUserRoles
          ? file.sharedUserRoles
          : {}
        existingShareUserIds = { ...existingShareUserIds, [shareUserId]: role }
        await ref.update({
          sharedUserRoles: existingShareUserIds,
          sharedUserIds: firebase.firestore.FieldValue.arrayUnion(shareUserId),
        })
      })

      const folders = Folders.filter(
        (d) =>
          (d.userId === userId || d.sharedUserIds?.includes(userId)) &&
          d.parentFolderId === folderId,
      )
      folders.map(async (file) => {
        const ref = database.collection('folders').doc(file.id)
        let existingShareUserIds = file.sharedUserRoles
          ? file.sharedUserRoles
          : {}
        existingShareUserIds = { ...existingShareUserIds, [shareUserId]: role }
        await ref.update({
          sharedUserRoles: existingShareUserIds,
          sharedUserIds: firebase.firestore.FieldValue.arrayUnion(shareUserId),
        })
        shareChildFolders(
          file.id,
          shareUserId,
          userId,
          role,
          Transcripts,
          Folders,
        )
      })

      if (folders.length < 1) {
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }
  return true
}
